import React from 'react'
import {intlConverter} from "../../../constants/utils";


const Team = ({item, intl,language}) => {


    return (
        <div className="row content">
            {item?.members.length > 0 ? item?.members?.map(value =>
                <div className="col-lg-12 col-md-12">
                    <div className="team-box-layout1">
                        <div className="media media-none-lg media-none-md media-none--xs">
                            <div className="item-img" style={{width: '200px', height: '200px'}}>
                                <img src={value.fileName} alt="Team1"
                                     className="img-fluid rounded-circle media-img-auto"
                                     style={{height: 'inherit', width: 'inherit'}}/>
                            </div>
                            <div className="media-body">
                                <div className="item-content">
                                    <h4 className="item-title">
                                        <a href="single-doctor.html">{value.firstname + " " + value.lastname}</a>
                                    </h4>
                                    <p className="designation font-weight-bold">{intlConverter(value?.position, language)}</p>

                                    <p dangerouslySetInnerHTML={{
                                        __html: intl.formatMessage(
                                            {id: intlConverter(value.about, language)},
                                        )
                                    }} style={{textAlign: 'justify',textJustify:'inter-character'}} className="mr-4"></p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>) : null}

        </div>
    )
}

export default Team;