import types from "../../actions/types/common/settingActionTypes";
import initialState from "../../../constants/utils/initialState";

const settingMgrReducer = (state = initialState.settingReducer, action) => {
    switch (action.type) {

        /**
         * LOAD SETTINGS LIST
         * */
        case types.LOAD_SETTING:
            return {...state, loadingItem: action.payload};
        case types.LOAD_SETTING_SUCCEEDED:
            return {...state, item: action.payload, loadingItem: false};
        case types.LOAD_SETTING_FAILED:
            return {...state, loadingItem: action.payload};

        /**
         * LOAD SETTINGS LIST
         * */
        case types.UPDATE_SETTING:
            return {...state, savingOrUpdating: action.payload};
        case types.UPDATE_SETTING_SUCCEEDED:
            return {...state, item: action.payload, savingOrUpdating: false};
        case types.UPDATE_SETTING_FAILED:
            return {...state, savingOrUpdating: action.payload};

        default:
            return state;
    }
};
export default settingMgrReducer;
