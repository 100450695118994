import {useEffect} from "react";
import {useSelector} from "react-redux";

function useDocumentTitle(title) {


    const {item} = useSelector(state => ({
        item: state.settingReducer.item,
    }));


    useEffect(() => {
        document.title = `${item ? item.appName : 'Zanmi Lasante'} | ${title}`;
        // document.getElementById('header-title').textContent = title;
    });

}


export default useDocumentTitle;
