import React from 'react';
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {PayPalButton} from "react-paypal-button-v2";
import {donateAmount} from '../../../../constants/options/data'
import FormControl from '@material-ui/core/FormControl';
import {makeStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert'


const currencies = [
    {
        value: 'USD',
        label: '$',
    },
    {
        value: 'EUR',
        label: '€',
    },
    {
        value: 'BTC',
        label: '฿',
    },
    {
        value: 'JPY',
        label: '¥',
    },
];

class DonateForm extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            amountSelected: null,
            currency: 'USD',
            label: '$'
        }
    }

    handleSelectAmount = (a) => {

        this.setState({amountSelected: a})

    }


    handleChangeAmount = e => {
        const val = e.target.value;
        this.setState({amountSelected: val})

    }


    handleChangeCurrency = e => {
        const val = e.target.value;
        let item = currencies.find(v => v.value === val);
        this.setState({currency: item.value, label: item.label})

    }


    paymentHandler = (details, data) => {

        if (details.status === "COMPLETED") {
            swal({
                title: `Merci pour votre don! Id:${details.id}`,
                text: "",
                icon: "success",
                button: "Next",
            }).then((value) => {
                //hdkjhfkjhd
            });
        } else {
            swal({
                title: `Votre don n'a pas ete complete:${details.id}`,
                text: "",
                icon: "error",
                button: "Next",
            }).then((value) => {
                //hdkjhfkjhd
            });
        }
    }

    render() {
        const {amountSelected, currency, label}=this.state;

        return (<>

            <div className="col-md-12 pb-2">
                <div class="isotope-classes-tab isotop-btn pt-2">
                    {donateAmount && donateAmount.length > 0 ? donateAmount.map(value => (
                            <a class={`nav-item  ${value.amount === amountSelected ? 'current text-white' : ''}`}
                               onClick={() => this.handleSelectAmount(value.amount)}>{label} {value.amount}</a>
                        )) : null}
                </div>
            </div>

            <div className="col-md-12  pb-4">
                <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-amount"
                                style={{fontSize: '16px', color: '#000'}}>Other</InputLabel>
                    <OutlinedInput
                        style={{fontSize: '18px'}}
                        id="outlined-adornment-amount"
                        value={amountSelected}
                        onChange={this.handleChangeAmount}
                        startAdornment={
                            <InputAdornment position="start" className="text-lg-left"
                                            style={{
                                                fontSize: '16px',
                                                color: '#000'
                                            }}>{label}
                            </InputAdornment>
                        }
                        endAdornment={
                            <TextField
                                id="outlined-select-currency"
                                select
                                value={currency}
                                style={{
                                    fontSize: '16px',
                                    color: '#000',
                                    position: 'relative',
                                    paddingRight: '0px',
                                    padding: '0px'
                                }}
                                onChange={this.handleChangeCurrency}
                            >
                                {currencies.map((option) => (
                                    <MenuItem key={option.value} value={option.value}
                                              style={{
                                                  fontSize: '16px',
                                                  color: '#000',
                                                  position: 'relative',
                                                  paddingRight: '0px'
                                              }}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        }
                        labelWidth={60}
                    />
                </FormControl>
            </div>


            <div className="col-md-12">
                {window.navigator.onLine === true ?
                    <PayPalButton
                        style={{
                            layout: 'vertical',
                            size: 'responsive',
                            color: 'gold',
                            shape: 'pill',
                            label: 'paypal',
                            fundingicons: true,
                            disable: true,
                            height: 40,
                        }}
                        amount={amountSelected}
                        currency={currency}
                        onSuccess={this.paymentHandler}
                        options={{
                            clientId: "AY7oTgnjHuYl7AlK8S0Ghhlr5cmFHaiqSuc1GevS_U_k62TTVu2rRQCGNXJ_3wFrM9guSSHMb3dQCRBO",
                        }}
                    /> : <h1>vous n'avez pas de connexion internet</h1>}
            </div>
            </>
        )
    }
}
export default DonateForm
