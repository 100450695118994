/**
 * Created by louidorpc on 11/12/20.
 */
import React from 'react';
import Donate from './details'
import {Modal, Button} from 'react-bootstrap'
import {FormattedMessage} from "react-intl";

export const DonateModal = ({sh, hide}) => {

    const [show, setShow] = React.useState(sh);
    return (
        <Modal

            show={sh}
            onHide={hide}
            style={{zIndex:'999999'}}
            dialogClassName="modal-90w"
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg modal-lg">
                    Donation
                </Modal.Title>
            </Modal.Header>
            <Modal.Body class="modal-lg">
                {/*<Donate setShow={setShow}/>*/}
                <embed src="https://secure.pih.org/a/haiti" width="100%" height={500}></embed>
            </Modal.Body>

            <Modal.Footer class="modal-lg pb-4">
                <div className="pt-4 pb-5 pr-5" style={{fontSIze: '16px'}}>
                    <Button className="float-right font-weight-bolder" onClick={hide}
                            style={{padding: '5px 5px', fontSize: '14px'}}><FormattedMessage
                        id={'app.label.close'}/></Button>
                </div>
            </Modal.Footer>
        </Modal>
    );

}
