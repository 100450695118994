/**
 * Created by louidorpc on 11/12/20.
 */
import React from 'react';
import Donate from './details'
import {Modal, Button} from 'react-bootstrap'
import {intlConverter} from "../../../../constants/utils";
import {FormattedMessage} from "react-intl";

export const UserModal = ({sh, hide, intl}) => {

    const [show, setShow] = React.useState(sh);
    const members = [
        {
            firstname: 'Rikenson', lastname: "JACQUES",
            fileName: '/assets/img/users/rj.jpg',
            position: 'Dev Manager',
            email: 'rjacques@pih.org',
        }, {
            firstname: 'Jean-Paul', lastname: "LOUIDOR",
            fileName: '/assets/img/users/160.jpeg',
            position: 'Analyste Programmeur',
            email: 'jpaullouidor@pih.org',
        }, {
            firstname: 'Prince Stanley Lovensky', lastname: "Jean Baptiste",
            fileName: '/assets/img/users/jbps.jpeg',
            position: 'Administrateur Système',
            email: 'prstanley@pih.org',
        }, {
            firstname: 'Peterson', lastname: "Edouard",
            fileName: '/assets/img/users/ed.jpeg',
            position: 'Analyste Programmeur',
            email: 'pedouard@zanmilasante.org',
        },
    ]
    return (
        <Modal

            show={sh}
            onHide={hide}
            dialogClassName="modal-90w pt-10"
            size={'lg'}
            style={{zIndex:'999999'}}
            aria-labelledby="example-modal-sizes-title-xl"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg modal-xl">
                    <FormattedMessage id={'app.label.dev_team'}/>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body class="modal-xl m-2">
                <div className="row content gutters-4">
                    {members.length > 0 ? members?.map(value =>
                        <div className="col-md-6 col-6">

                            <div className="team-box-layout1 card" style={{height:'250px'}}>
                                <div className="media media-none-lg media-none-md media-none--xs">
                                    <div className="item-img" style={{width: '100px', height: '100px'}}>
                                        <img src={value.fileName} alt="Team1"
                                             className="img-fluid rounded-circle media-img-auto"
                                             style={{height: 'inherit', width: 'inherit'}}/>
                                    </div>
                                    <div className="media-body">
                                        <div className="item-content">
                                            <h5 className="item-title">
                                                <a href="single-doctor.html">{value.firstname + " " + value.lastname}</a>
                                            </h5>
                                            <p className="designation font-weight-bold"
                                               style={{textAlign: 'left', fontSize: '9px'}}>
                                                <span style={{
                                                    textAlign: 'left',
                                                    fontSize: '13px'
                                                }}>{value?.position}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>) : null}

                </div>
            </Modal.Body>

            <Modal.Footer class="modal-lg pb-4">
                <div className="pt-4 pb-5 pr-5" style={{fontSIze: '16px'}}>
                    <Button className="float-right font-weight-bolder" onClick={hide}
                            style={{padding: '5px 5px', fontSize: '14px'}}><FormattedMessage
                        id={'app.label.close'}/></Button>
                </div>
            </Modal.Footer>
        </Modal>
    );

}
