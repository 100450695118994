const commonActionTypes = {
    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * -------------------------------------------
     * */
    SWITCH_LOCALE: "SWITCH_LOCALE",

};

export default commonActionTypes;
