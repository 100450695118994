import * as React from "react";
import {NavLink, withRouter, useParams} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import Map from '../../help/map'
import {intlConverter} from "../../../../constants/utils";
import {useContext} from "react";
import {GlobalContext} from "../../../index";


const SideBar = ({items}) => {
    const {module, page, id} = useParams();
    const {language} = useContext(GlobalContext)

    return (
        <div class="row">
            <div className="sidebar-widget-area sidebar-break-md col-md-3 no-equal-item pt-0">
                <div className="widget widget-department-info">
                    <h3 className="section-title title-bar-primary"><FormattedMessage id={'app.label.all_sectors'}/>
                    </h3>
                    <ul className="nav nav-tabs tab-nav-list bg-white">
                        {items && items?.sectors?.map((value, i) =>
                            <li className="nav-item">
                                <a className={value.id === id ? 'active' : ''} href={`#sector${i}`} data-toggle="tab"
                                   aria-expanded="false">
                                    <span> {intlConverter(value.name, language)}</span>

                                </a>
                            </li>
                        )}

                    </ul>
                </div>

            </div>

            <div className="col-md-9 no-equal-item pt-3" style={{background: '#fff'}}>
                <div className="tab-content">
                    {items && items?.sectors?.map((value, i) => (
                        <div role="tabpanel" className={`tab-pane fade ${value.id == id ? 'active show' : ''}`}
                             id={`sector${i}`}>
                            <div class="col-xl-12 col-lg-12 col-12">
                                <div class="blog-detail-box">
                                    <div className="item-content m-4">
                                        <h2 className="item-title">
                                            {intlConverter(value.name, language)}
                                        </h2>
                                    </div>
                                    <div class="blog-detail-title oval">
                                        <div class="item-img m-4">
                                            {/*<video src={value.video} controls="controls" width="100%" height="300px" style={{background: `url(${value.avatar}) no-repeat`}}></video>*/}
                                            <img src={value.fileName} class="img-fluid oval2" alt="blog" width="100%"
                                                 height="300px"/>
                                        </div>

                                    </div>

                                    <div className="single-departments-box-layout1">

                                        <div className="item-content">
                                            <p dangerouslySetInnerHTML={{
                                                __html: intlConverter(value.description, language)
                                            }} className="justify-content-center m-4"
                                               style={{textAlign: 'justify'}}></p>

                                        </div>

                                    </div>

                                    {value?.gallery &&
                                    <div class="isotope-wrap">
                                        <div class="row featuredContainer zoom-gallery">
                                            {value?.gallery?.slides?.map(v =>
                                                <div class="col-lg-4 col-md-6 col-sm-6 col-12 dental cardiology">
                                                    <div class="gallery-box-layout1">
                                                        <img src={v.fileName} alt="gallery" class="img-fluid"/>
                                                        <div class="item-icon">
                                                            <a href={v.fileName} class="popup-zoom"
                                                               data-fancybox-group="gallery" title="">
                                                                <i class="flaticon-search"></i>
                                                            </a>
                                                        </div>

                                                    </div>
                                                </div>)}

                                        </div>
                                    </div>}

                                </div>
                            </div>

                        </div>
                    ))}
                </div>
            </div>

        </div>

    )
}


export default SideBar;

