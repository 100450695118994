import React from "react";
import {Link, NavLink} from "react-router-dom";
import {FormattedMessage} from "react-intl";


const Pagination = () => {
    const pages = [1, 2];

    return (
        <ul className="pagination-layout2 margin-t-50r">
            <li>
                <Link to="#">
                    <FormattedMessage id={`app.label.preview`}/>
                </Link>
            </li>
            {pages.map((page, i) =>
                <li className="active" key={i}>
                    <NavLink to="#">{page}</NavLink>
                </li>
            )}
            <li>
                <Link to="#">
                    <FormattedMessage id={`app.label.next`}/>
                </Link>
            </li>
        </ul>
    );

}


export default React.memo(Pagination);
