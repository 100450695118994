import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router-dom";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../constants/utils";
import {useDispatch, useSelector} from "react-redux";
import {useContext, useEffect} from "react";
import actions from "../../../thunks/common/coreThunks";
import StairHeader from "../../layout/common/SatairHeader";
import SideBar from "./SideBar";
import {GlobalContext} from "../../index";
import Team from "./team";

const SiteDetailPage = injectIntl(({intl}) => {
    useDocumentTitle(getIntlMsg(intl, `app.label.research`));


    const dispatch = useDispatch();

    const {module, secondLevelPage, action, page, id} = useParams();
    const params = {intl, options: ['pages', 'research']}


    useEffect(() => {
        dispatch(actions.loadItems(params))
    }, []);

    const {item, loading, items} = useSelector(state => ({
        items: state.pagesReducer.research?.item,
        loading: state.pagesReducer.research?.loadingItem
    }));

    const {settings, language} = useContext(GlobalContext);


    return (<>
            <div style={{height: '40vh', width: '100%'}} className="pt-4 pb-4 mr-4">
                <div id="googleMap" style={{width: "100%", height: "100%",}}></div>

            </div>
            <section class="single-department-wrap-layout1 bg-light-primary100 pt-4">
                <div class="container">
                    <SideBar value={items?.research}/>
                    <div className="rt-el-title section-heading heading-dark text-center heading-layout1 style1 theme2">
                        <h2 className="rtin-title pt-5"><FormattedMessage id={'app.label.team'}/></h2>
                    </div>
                    <Team item={items} intl={intl} language={language}/>

                </div>
            </section>
        </>
    );
});


export default React.memo(withRouter(SiteDetailPage))

