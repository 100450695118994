import initialState from "../../../constants/utils/initialState";
import types from "../../actions/types/common/coreActionTypes";

const commonReducer = (state = initialState.coreReducer, action) => {
    switch (action.type) {

        case types.SWITCH_LOCALE:
            return {...state, locale: action.payload};

        default:
            return state;
    }
};
export default commonReducer;