import * as React from "react";
import {injectIntl} from "react-intl";
import useDocumentTitle from "../../../common/hooks/useDocumentTitle";
import {getIntlMsg, intlConverter} from "../../../../constants/utils";
import {useParams} from "react-router-dom";
import StairHeader from "../../../layout/common/SatairHeader";
import {useDispatch, useSelector} from "react-redux";
import {useContext, useEffect} from "react";
import actions from "../../../../thunks/common/coreThunks";
import {GlobalContext} from "../../../index";


const getCollapse = (i) => (
    i === 1 ? 'Two' : i === 2 ? 'Three' : i === 3 ? 'Four' : i === 4 ?
        'Five' : i === 5 ? 'Six' : i === 6 ? 'Seven' : ''
);


const FaqPage = injectIntl(({intl, Faqs}) => {
    const {page} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.${page}`));

    const {settings, language} = useContext(GlobalContext);

    return (
        <>
            <StairHeader title={page} subtitle={`help`} mt="50px"/>
            <section class="faq-wrap-layout1">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-8 col-lg-8 col-12">
                            <div class="faq-content-layout1">
                                <div class="item-heading">
                                    <h2 class="item-title title-bar-primary4">{getIntlMsg(intl, `app.label.question`)}</h2>
                                    <p class="sub-title"></p>
                                </div>
                                <div class="faq-list-layout1">
                                    <div class="panel-group" id="accordion">
                                        {settings && settings?.helps?.length > 0 ? settings?.helps?.map((value, index) =>
                                            <div class="panel panel-default">
                                                <div class={`panel-heading ${index === 0 ? 'active' : ''}`}>
                                                    <div class="panel-title">
                                                        <a aria-expanded="false"
                                                           class={`${index === 0 ? 'accordion-toggle text-row-1' : 'accordion-toggle collapsed'} `}
                                                           data-toggle="collapse" data-parent="#accordion"
                                                           href={`${index === 0 ? '#collapseOne' : `#collapse${getCollapse(index)}`}`}>
                                                            <p dangerouslySetInnerHTML={{
                                                                __html: intlConverter(value.title, language)
                                                            }} style={{textAlign: 'justify'}}></p>

                                                        </a>
                                                    </div>
                                                </div>
                                                <div aria-expanded="false"
                                                     id={`${index === 0 ? 'collapseOne' : `collapse${getCollapse(index)}`}`}
                                                     role="tabpanel"
                                                     class={`panel-collapse collapse ${index === 0 ? 'show' : ''}`}>
                                                    <div class="panel-body">
                                                        <p dangerouslySetInnerHTML={{
                                                            __html: intlConverter(value.description, language)
                                                        }} style={{textAlign: 'justify'}}></p>

                                                    </div>
                                                </div>
                                            </div>) : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 d-none d-lg-block">
                            <div class="faq-img-layout1">
                                <img src="/assets/img/int.jpeg" alt="about" class="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
});

export default React.memo(FaqPage)

