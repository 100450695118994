import {typeNameFormatter} from "../../../../constants/utils";

/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * -----------------------------------------------
 * */
export const switchItemValue = params => {
    const {options, value} = params;
    return {type: `RESET_${typeNameFormatter(options)}`, value};
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * ---------------------------------------------------
 * */
export const setChangeItemStatus = params => {
    const {options, item} = params;

    return {type: `CHANGE_${typeNameFormatter(options)}_STATUS`, item};
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * -----------------------------------------------
 * */
export const clearItem = params => {
    const {options} = params;
    return {type: `RESET_${typeNameFormatter(options)}`};
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
export const setLoadedItemSucceeded = params => {
    const {options, payload} = params;
    return {
        type: `LOAD_${typeNameFormatter(options)}_SUCCEEDED`, payload
    };
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
export const setLoadedItemFailed = params => {
    const {options} = params;
    return {type: `LOAD_${typeNameFormatter(options)}_FAILED`, payload: false};
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
export const setLoadedItem = params => {
    const {options} = params;
    return {type: `LOAD_${typeNameFormatter(options)}`, payload: true};
};

/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
export const setLoadedItemsSucceeded = params => {
    const {options, payload} = params;
    return {
        type: `LOAD_${typeNameFormatter(options, false)}_PAGE_SUCCEEDED`, payload
    };
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
export const setLoadedItemsFailed = params => {
    const {options} = params;
    return {type: `LOAD_${typeNameFormatter(options, false)}_PAGE_FAILED`, payload: false};
};

/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * ---------------------------------------------
 * */
export const setLoadedItems = params => {

    const {options} = params;
    return {type: `LOAD_${typeNameFormatter(options, false)}_PAGE`, payload: true};
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
export const setUploadSucceeded = params => {
    const {options, payload} = params;
    return {
        type: `UPLOAD_${typeNameFormatter(options)}_SUCCEEDED`, payload
    };
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
export const setUploadFailed = params => {
    const {options} = params;
    return {type: `UPLOAD_${typeNameFormatter(options)}_FAILED`, payload: false};
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
export const setUpload = params => {
    const {options} = params;
    return {type: `UPLOAD_${typeNameFormatter(options)}`, payload: true};
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * ---------------------------------------------------
 * */
export const setLoadItemsPAGEOfValue = params => {
    const {options, items} = params;

    return {type: `LOAD_${typeNameFormatter(options)}_PAGE_OF_VALUE`, items};
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
export const setSavedOrUpdatedItem = params => {
    const {options, action} = params;
    return {
        type: `${(action === "save") ? 'SAVE_' : (action === "upload") ? '' : 'UPDATE_'}${typeNameFormatter(options)}`,
        payload: true
    };
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
export const setSavedOrUpdatedItemFailed = params => {
    const {options, action} = params;
    return {
        type: `${(action === "save") ? 'SAVE_' : (action === "upload") ? '' : 'UPDATE_'}${typeNameFormatter(options)}_FAILED`,
        payload: false
    };
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * ---------------------------------------------------
 * */
export const setSavedOrUpdatedItemSucceeded = params => {
    const {options, action, payload} = params;

    return action === "save"
        ? {type: `SAVE_${typeNameFormatter(options)}_SUCCEEDED`, payload}
        : action === 'upload' ? {type: `${typeNameFormatter(options)}_SUCCEEDED`, payload}
            : {type: `UPDATE_${typeNameFormatter(options)}_SUCCEEDED`, payload};
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * -----------------------------------------------------------
 * */
export const setDeletedItemSucceeded = params => {
    const {options, status, item} = params;
    const payload = {item, status};

    return {type: `DELETE_${typeNameFormatter(options)}_SUCCEEDED`, payload};
};
/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
export const setDeletedItemFailed = params => {
    const {options} = params;
    return {type: `DELETE_${typeNameFormatter(options)}_FAILED`, payload: false};
};

/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
export const setDeletedItem = params => {
    const {options} = params;
    return {
        type: `DELETE_${typeNameFormatter(options)}`, payload: true
    };
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * -----------------------------------------------------------
 * */
export const setSwitchItem = params => {

    return {type: `SWITCH_LOCALE`, payload: params};
};
