import React from "react"
import PageManager from "../../components/pages";
import HomePage from "../../components/pages/home";

const routes = [
    {
        path: "/",
        exact: true,
        main: () => <HomePage/>
    },
    {
        path: "/:module",
        exact: true,
        main: () => <PageManager/>
    },
    {
        path: "/:module/:page",
        exact: true,
        main: () => <PageManager/>
    },
    {
        path: "/:module/:page/:id",
        exact: true,
        main: () => <PageManager/>
    },
];

export default routes;