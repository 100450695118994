import * as React from "react";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";


const Thumbnail = ({avatar, title, description}) => {

    return (
        <div className="col-xl-4 col-lg-6 col-md-6 col-12">
            <div className="departments-box-layout5">
                <div className="item-img">
                    <img src={`/assets/img/department/${avatar}`} alt="department" className="img-fluid"/>
                    <div className="item-content">
                        <h3 className="item-title title-bar-primary3">
                            <Link to="#">
                                <FormattedMessage id={`app.label.${title}`}/>
                            </Link>
                        </h3>
                        <p>{description}</p>
                        <Link to="#" className="item-btn">
                            <FormattedMessage id={`app.label.details`}/>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Thumbnail)

