import * as React from "react";
import {data} from '../../../../constants/options/data';
import Slide from "./Slide";
import {DonateModal} from '../../donate/sections/donateModal';
import {FormattedMessage} from "react-intl";
import {Fade} from 'react-slideshow-image'
import {intlConverter} from "../../../../constants/utils";

const Slider = props => {
    const {slides, language} = props

    const [state, setState] = React.useState({
        label: 'Francais', show: false
    });

    function setShowM() {
        setState({
            show: true,
        })
    }

    function hide() {
        setState({
            show: false,
        })
    }

    const {lang, label, show} = state;


    const datas = {

        "slider": {
            "pictures": slides.map((v, i) => ({src: v.fileName, alt: 'slider', title: `#slider-direction-${i + 1}`})),
            "contents": slides.map((v, i) => ({id: i + 1, title: v.name.frContent}))
        }

    }

    // const getImages = () => {
    //     let div = document.getElementsByClassName('nivo-box');
    //     let img = div.querySelector('img');
    //     img.setAttribute("src","9888888888888888888888888888")
    //     console.log("img");
    // }


    const slideImages = [
        'http://127.0.1.1:4000/api/website/slides/images/bc79b02e_386f_45f5_ab4e_5652e2e8921a.png',
        'http://127.0.1.1:4000/api/website/slides/images/bc79b02e_386f_45f5_ab4e_5652e2e8921a.png',
        'http://127.0.1.1:4000/api/website/slides/images/bc79b02e_386f_45f5_ab4e_5652e2e8921a.png',
        'http://127.0.1.1:4000/api/website/slides/images/bc79b02e_386f_45f5_ab4e_5652e2e8921a.png',
        'http://127.0.1.1:4000/api/website/slides/images/bc79b02e_386f_45f5_ab4e_5652e2e8921a.png'
    ];

    const properties = {
        duration: 5000,
        transitionDuration: 500,
        infinite: true,
        indicators: true,
        arrows: true,
        onChange: (oldIndex, newIndex) => {
        }
    }
    return (
        <div className="slider-area slider-layout4 bg-light-primary slider-top-margin">
            <div className="bend niceties preview-1">
                <div id="ensign-nivoslider-1" className="slides">
                    {slides?.map((picture, i) =>
                        imagesItem(picture, i + 1)
                    )}
                </div>
                {slides?.map((content, i) =>
                    <div id={`slider-direction-${i + 1}`} className="t-cn slider-direction">
                        <div className={`slider-content s-tb slide-${i + 1}`}>
                            <div className="text-left title-container s-tb-c">
                                <div className="container">
                                    <div className="slider-big-text">
                                        <span>{intlConverter(content.name, language)}</span>
                                    </div>
                                    <div dangerouslySetInnerHTML={{
                                        __html: intlConverter(content?.description, language)
                                    }} className="slider-paragraph padding-right text-color-white"
                                         style={{color: '#fff'}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

const imagesItem = (img, index) => (<>
        {<img src={`${img?.fileName}`} alt={'slider'} title={`#slider-direction-${index}`} key={index}
              style={{background: "#0000", background: "corver"}} className="imgs" data-image="test"/>}</>
);


export default React.memo(Slider)

