import React, {useState} from "react";
import {injectIntl, FormattedMessage} from "react-intl";
import {getIntlMsg} from "../../../../constants/utils";
import {Teams} from "../../../../constants/options/data";
import {useParams} from "react-router-dom";
import {LeadershipModal} from './leadershipModal'


const LeadershipPage = injectIntl(({intl}) => {

        const [state, setState] = useState({
            show: false
        });

        function setShowM() {
            setState({
                show: true
            })
        }

        function hide() {
            setState({
                show: false
            })
        }

        const {show}=state;

        const translated = intl.formatMessage(
            {id: 'app.label.team_desc1'},
            {value: '<strong>STRONG</strong>'}
        );


        return (
            <>
            <div className="single-departments-box-layout1 pt-5">

                <div className="item-content pt-3">
                    <div className="item-content-wrap">
                        <h3 className="item-title title-bar-primary5">
                            Leadership
                        </h3>
                        <span className="sub-title">

                                </span>
                        <div class="row">
                                    <div class="col-lg-12 col-md-12">

                                        <div class="team-box-layout1">
                                            <div class="media media-none-lg media-none-md media-none--xs">
                                                <div class="item-img">
                                                    <img src='/assets/img/team/team01.png' alt="Team1"
                                                         class="img-fluid rounded-circle media-img-auto" width='170px'
                                                         height="170px"/>
                                                </div>
                                                <div class="media-body">
                                                    <div class="item-content">
                                                        <h4 class="item-title">
                                                            <a href="single-doctor.html">Loune Viaud</a>
                                                        </h4>
                                                        <p class="designation font-weight-bold"><FormattedMessage
                                                            id={'app.label.exec_title1'} defaultMessage=""/></p>

                                                        <div dangerouslySetInnerHTML={{
                                                            __html: intl.formatMessage(
                                                                {id: 'app.label.exec_desc1'},
                                                                {value: '<strong>STRONG</strong>'}
                                                            )
                                                        }} style={{textAlign:'justify'}} className="mr-4"></div>


                                                        <ul class="item-btns">

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                        </div>

                        <div>

                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }
);

export default React.memo(LeadershipPage)

