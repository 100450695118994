import React from "react";
import {Field, reduxForm} from "redux-form";
import {renderField} from "../../../../common/utils/FormComponent";
import {FormattedMessage} from "react-intl";
import {connect, useDispatch, useSelector} from "react-redux";
import {validate} from "./validator";
import {FieldArray} from "redux-form";
import renderDocument from './documents'
import {RecruitmentMapper} from "../../../../../constants/models/Recruitment";
import actions from "../../../../../thunks/common/coreThunks";
import swal from 'sweetalert';
import {getIntlMsg} from "../../../../../constants/utils";

const FormComponent = props => {
    const {
        handleSubmit,
        valid,
        reset,
        pristine,
        submitting,
        intl
    } = props;

    const {message, applicant} = useSelector(state => ({
        message: state.pagesReducer?.recruitment?.message,
        applicant: state.pagesReducer?.recruitment?.applicant,
    }));
    const dispatch = useDispatch();
    const resetState = () => {
        reset('recruitmentForm');
        dispatch(actions.resetItem({options: ['recruitment']}))

        swal({
            title: `${applicant?.firstName} ${applicant?.lastName}`,
            text: applicant?.message === "The user has already submitted an application for this position" ?
                getIntlMsg(intl, 'app.label.post_success_info3') : applicant?.message === "User application is updated" ? getIntlMsg(intl, 'app.label.post_success_info2') : getIntlMsg(intl, 'app.label.post_success_info1'),
            icon: applicant?.message === "The user has already submitted an application for this position" ?
                'warning' : applicant?.message === "User application is updated" ? 'info' : 'success',
            button: "Ok",
            SuccessMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    document.location = "/jobs";
                } else {
                    document.location = "/jobs";
                }
            });


    }

    if (message === 'success') resetState()
    return (
        <div className="col-md-4 pt-4">
            <div className="single-departments-box-layout1 bg-white">
                <div className="item-content">
                    <div className="item-content-wrap">


                        <div className="contact-box-layout1">
                            <h4 className="title title-bar-primary4 ml-4">
                                <FormattedMessage id={'app.label.formApplyTitle'}
                                                  defaultMessage={`Envoyez-nous un message`}/></h4>

                            <form onSubmit={handleSubmit} className="contact-form-box">
                                <div className="col col-xs-12">
                                    <Field
                                        className="form-control"
                                        id="option-message"
                                        name="lastName"
                                        component={renderField}
                                        type="text"
                                        label="app.label.lastName"
                                        placeholder="app.placeholder.lastName"
                                    />

                                </div>
                                <div className="col col-xs-12">
                                    <Field
                                        className="form-control"
                                        id="option-message"
                                        name="firstName"
                                        component={renderField}
                                        type="text"
                                        label="app.label.firstName"
                                        placeholder="app.placeholder.firstName"
                                    />

                                </div>

                                <div className="col col-xs-12">
                                    <Field
                                        className="form-control"
                                        id="option-message"
                                        name="email"
                                        component={renderField}
                                        type="email"
                                        label="app.label.email"
                                        placeholder="app.placeholder.email"
                                    />

                                </div>

                                <div className="col col-xs-12">
                                    <Field
                                        className="form-control"
                                        id="option-message"
                                        name="phone"
                                        component={renderField}
                                        type="text"
                                        label="app.label.phone"
                                        placeholder="app.placeholder.phone"
                                    />

                                </div>


                                <div className="col-md-12 pb-4 pt-4">

                                    <FieldArray component={renderDocument} name={'documents'}/>
                                </div>


                                <div className="col col-xs-12 form-group margin-b-none" style={{textAlign: 'end'}}>
                                    <div className={!valid || pristine ? 'd-none' : ''}>
                                        <button type="submit"
                                                className="item-btn"
                                                disabled={pristine || submitting}>
                                            <FormattedMessage id={'app.label.apply'} defaultMessage={`Postuler`}/>
                                        </button>
                                    </div>
                                    <div id="loader">
                                        <i className="fa fa-refresh fa-spin fa-3x fa-fw"></i>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = ({pagesReducer: {recruitmentItem}}) => {
    const initVal = recruitmentItem ? new RecruitmentMapper(recruitmentItem) : null;
    return {
        initialValues: recruitmentItem !== null ? {...initVal, lang: 'fr'} : null
    }
};
export default connect(
    mapStateToProps
)(reduxForm({
    form: 'recruitmentForm',
    validate
})(FormComponent));
