/**
 * Created by louidorpc on 10/29/20.
 */
import React from 'react'
import {intlConverter} from '../../../../constants/utils'
import moment from "moment";
import {FormattedMessage} from "react-intl";
import 'moment/locale/fr'
import 'moment/locale/en-au'


export const Section1 = ({item, language}) => {
    moment.locale(language)
    return (
        <section class="service-wrap-layout1 bg-light-primary100 pb-0" style={{background: '#ffffff'}}>
            <div class="container">
                <div class="row no-gutters service-inner-layout1">
                    {item?.statistics?.map(v => {
                        if (v.page === "HOME") {

                            return (
                                <div class="single-item col-lg-3 col-md-2 col-12">
                                    <div class="service-box-layout1">
                                        <div class="item-icon">
                                            <i class="flaticon-hospital"></i>
                                        </div>
                                        <h4 class="item-title">
                                            <a href="single-service.html">{intlConverter(v.name, language)}</a>
                                        </h4>
                                        <p dangerouslySetInnerHTML={{
                                            __html: intlConverter(v.description, language)
                                        }}></p>
                                    </div>
                                </div>)
                        }
                    })}

                </div>
            </div>
        </section>
    );
}

export const SectionAbout = (data, settings, language) => {
    moment.locale(language)
    return (
        <section class="about-wrap-layout2 pt-2">
            <div class="container">
                <div class="row">
                    <div class="about-box-layout6 col-lg-6">
                        <h2 class="item-title"><FormattedMessage id={'app.label.welcome_to'}
                                                                 defaultMessage={'Bienvenue A'}/>
                            <span> Zanmi lasante </span></h2>
                        <div class="sub-title">Mission</div>
                        <p dangerouslySetInnerHTML={{
                            __html: intlConverter(settings?.mission, language)
                        }}>
                        </p>

                        <div class="sub-title">Vision</div>
                        <p dangerouslySetInnerHTML={{
                            __html: intlConverter(settings?.vision, language)
                        }}></p>
                    </div>
                    <div class="about-box-layout7 col-lg-6">


                        <div class="item-video" style={{}}>
                            <img src={data && data.video ? data.video.image : null} alt="about" width="100%"
                                 height="595"
                                 style={{border: "2px solid #ff6900", padding: '20px',}}/>
                            {/*<a class="play-btn popup-youtube" href={ data && data.video ? data.video.url : null}>*/}
                            {/*<i class="flaticon-play-button"></i>*/}
                            {/*</a>*/}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export const SectionOrigin = (data) => (
    <section class="about-wrap-layout2 pt-0">
        <div class="container">
            <div class="row">
                <div class="about-box-layout6 col-lg-6">
                    <h2 class="item-title">Origine de
                        <span> Zanmi lasante </span></h2>

                    <p>
                        {data ? data.p1 : null}
                    </p>
                    <p>
                        {data ? data.p2 : null}
                    </p>
                    <p>
                        {data ? data.p3 : null}
                    </p>
                    <p>
                        {data ? data.p4 : null}
                    </p>
                    <p>
                        {data ? data.p5 : null}
                    </p>

                </div>
                <div class="about-box-layout7 col-lg-6">
                    <div class="item-video">
                        <img src={data && data.video ? data.video.image : null} alt="about" width="573" height="595"
                             style={{border: "2px solid #3364e2", borderRadius: '10px', padding: '1px'}}/>
                        <a class="play-btn popup-youtube" href={data && data.video ? null : data.video.url}>
                            <i class="flaticon-play-button"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export const sectionSector = (data, item, lang) => {
    moment.locale(lang)
    return (

        <section class="departments-wrap-layout2 bg-light-secondary100 pt-4 pb-2">
            <img class="left-img img-fluid" src="assets/img/figure/figure8.png" alt="figure"/>
            {item && item?.sectors?.length > 0 ?
                <div class="container">
                    <div class="section-heading heading-dark text-left heading-layout1">
                        <h2><FormattedMessage id={'app.label.sectors'}/></h2>
                        <p><FormattedMessage id={'app.label.sector_dif'}/></p>
                        <div id="owl-nav1" class="owl-nav-layout1">
                        <span class="rt-prev">
                            <i class="fas fa-chevron-left"></i>
                        </span>
                            <span class="rt-next">
                            <i class="fas fa-chevron-right"></i>
                        </span>
                        </div>
                    </div>

                    <div class="rc-carousel nav-control-layout2" data-loop="true" data-items="4" data-margin="20"
                         data-autoplay="false" data-autoplay-timeout="5000" data-custom-nav="#owl-nav1"
                         data-smart-speed="2000"
                         data-dots="false" data-nav="false" data-nav-speed="false" data-r-x-small="1"
                         data-r-x-small-nav="true"
                         data-r-x-small-dots="false" data-r-x-medium="2" data-r-x-medium-nav="false"
                         data-r-x-medium-dots="false"
                         data-r-small="2" data-r-small-nav="false" data-r-small-dots="false" data-r-medium="3"
                         data-r-medium-nav="false" data-r-medium-dots="false" data-r-large="4" data-r-large-nav="false"
                         data-r-large-dots="false" data-r-extra-large="4" data-r-extra-large-nav="false"
                         data-r-extra-large-dots="false">

                        {item?.sectors?.map((value, i) => (

                            <div class="departments-box-layout5">
                                <a href={`sectors/detail/${value.id}`}>
                                    {/*<a href={`#`}>*/}
                                    <div class="item-img" style={{height: "250px"}}>
                                        <img src={value.fileName} alt="department" class="img-fluid"
                                        />
                                        <div class="item-content">
                                            <h3 class="item-title title-bar-primary3"><a
                                                href={`sectors/detail/${value.id}`}>{intlConverter(value.name, lang)}</a>
                                            </h3>
                                            <div className={'item-title bg-title'}>
                                                <p dangerouslySetInnerHTML={{
                                                    __html: intlConverter(value.about, lang)
                                                }} className="ellipse" style={{fontWeight: 'normal'}}></p>
                                            </div>
                                            {/*<a href={`#`} class="item-btn">DETAILS</a>*/}
                                            <a href={`/sectors/detail/${value.id}`} class="item-btn">DETAILS</a>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}

                    </div>
                </div> : null}
        </section>
    )
}


export const sectionTeste = (data, item, lang) => (

    <section class="testmonial-wrap-layout2 bg-common" data-bg-image="assets/img/brand/1.jpg">
        <div class="container">
            <div class="rc-carousel dot-control-layout2" data-loop="true" data-items="1" data-margin="30"
                 data-autoplay="true" data-autoplay-timeout="5000" data-smart-speed="2000" data-dots="true"
                 data-nav="false"
                 data-nav-speed="false" data-r-x-small="1" data-r-x-small-nav="false" data-r-x-small-dots="true"
                 data-r-x-medium="1" data-r-x-medium-nav="false" data-r-x-medium-dots="true" data-r-small="1"
                 data-r-small-nav="false" data-r-small-dots="true" data-r-medium="1" data-r-medium-nav="false"
                 data-r-medium-dots="true" data-r-large="1" data-r-large-nav="false" data-r-large-dots="true"
                 data-r-extra-large="1" data-r-extra-large-nav="false" data-r-extra-large-dots="false">
                {item && item?.testimonials?.length > 0 ? item?.testimonials?.map(v =>
                    <div class="testmonial-box-layout1">
                        <div class="item-img">
                            <img src={v.fileName} class="img-fulid rounded-circle"
                                 alt="Robert Addison" width={'200px'}/>
                        </div>
                        <div class="item-content">

                            <div dangerouslySetInnerHTML={{
                                __html: intlConverter(v.content, lang)
                            }}></div>
                            <h3 class="item-title">{v.fullName}</h3>
                            <h4 class="sub-title">{v.occupation}</h4>
                        </div>
                    </div>) : null}

            </div>
        </div>
    </section>
)


export const sectionNews = (data, item, lang) => {
    moment.locale(lang)
    return (

        <section class="blog-wrap-layout4 bg-light-secondary100 pt-2">
            <div class="container">
                <div class="section-heading heading-dark text-center heading-layout1">
                    <h2><FormattedMessage id={'app.label.similary_article'}/></h2>
                </div>
                <div class="row">
                    {item && item.news.length > 0 ? item.news.map(v =>
                        <div class="single-item col-lg-4 col-md-12 col-12">
                            <div class="blog-box-layout5">
                                <div class="item-img" style={{height: '200px'}}>
                                    <a href={`/news/detail/${v.id}`}>

                                        <img src={v.fileName} class="img-fluid" alt="blog" height={'200px'}/>
                                    </a>
                                    <div class="post-date">{moment(v.created).format('DD')}
                                        <span>{moment(v.created).format('MMM YYYY')}</span>
                                    </div>
                                </div>
                                <div class="item-content">
                                    <h3 class="item-title">
                                        <a href={`/news/detail/${v.id}`}>
                                            {intlConverter(v.title, lang)}</a>
                                    </h3>
                                    <div className="item-title">
                                        <p dangerouslySetInnerHTML={{
                                            __html: intlConverter(v.content, lang)
                                        }} className="ellipse"></p>
                                    </div>
                                </div>
                            </div>
                        </div>) : null}
                </div>
            </div>
        </section>
    )
}


export const sectionPartners = (data) => (
    <section class="brand-wrap-layout2 bg-light-primary100">
        <div class="container">
            <div class="section-heading heading-dark text-center heading-layout1">
                <h2>Nos partenaires</h2>
            </div>
            <div class="rc-carousel nav-control-layout4" data-loop="true" data-items="5" data-margin="30"
                 data-autoplay="false" data-autoplay-timeout="5000" data-smart-speed="2000" data-dots="false"
                 data-nav="true" data-nav-speed="false" data-r-x-small="2" data-r-x-small-nav="true"
                 data-r-x-small-dots="false" data-r-x-medium="2" data-r-x-medium-nav="true" data-r-x-medium-dots="false"
                 data-r-small="3" data-r-small-nav="true" data-r-small-dots="false" data-r-medium="5"
                 data-r-medium-nav="true" data-r-medium-dots="false" data-r-large="5" data-r-large-nav="true"
                 data-r-large-dots="false">
                <div class="brand-box-layout3">
                    <img src="/assets/img/brand/part1.png" alt="brand" class="img-fluid"/>
                </div>

            </div>
        </div>
    </section>
)