import * as React from "react";
import {injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router-dom";
import pages from "../../../constants/options/_pages";
import DefaultPage from "./default";
import SiteDetailPage from "./details";
import {sectionPartners} from "../home/sections"
import {useDispatch, useSelector} from "react-redux";
import {useContext, useEffect} from "react";
import actions from "../../../thunks/common/coreThunks";
import {GlobalContext} from "../../index";


const NetworkPage = injectIntl(({intl}) => {
    const {page} = useParams();

    return (
        <>
        {_onLoadPage(page)}
        </>
    );
});

function _onLoadPage(page, loading, item) {
    switch (page) {
        case pages.NETWORK_MODULE_SITE:
            return <SiteDetailPage/>
        default:
            return <SiteDetailPage/>

    }
}


export default React.memo(withRouter(NetworkPage))

