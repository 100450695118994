export class RecruitmentModel {

    constructor(params) {
        if (params) {

            const {
                firstName,
                lastName,
                email,
                phone,
                documents
            } = params;
            this.applicant = {
                email: email ? email : null,
                phone: phone ? phone : null,
                firstName: firstName ? firstName : null,
                lastName: lastName ? lastName : null,

                documents: documents ? documents.map(v => ({
                    documentName: v.name,
                    file: v?.file?.fileName ? v?.file : null
                })) : []

            }
        }
    }
}


export class RecruitmentMapper {

    constructor(params) {
        if (params) {

            console.log("=+++++++++++LOLOLO++++++++++++++++", params)

            const {
                requiredDocs
            } = params;


            this.documents = requiredDocs ? requiredDocs.map(v => ({
                documentName: v
            })) : [];

        }
    }
}


const arrayMapperDocs = (arrays) => {
    let str = []
    if (arrays) {
        for (const [key, value] of Object.entries(arrays)) {
            str.push(value?.name);
        }
    }
    return str;
}


