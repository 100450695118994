/**
 * Created by louidorpc on 11/5/20.
 */
import * as React from "react";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import {getIntlMsg, intlConverter} from "../../../constants/utils";
import {injectIntl} from "react-intl";
import DonateDetail from './sections/details'
import {useParams} from "react-router-dom";
import StairHeader from "../../layout/common/SatairHeader";
import {useContext, useEffect} from "react";
import actions from "../../../thunks/common/coreThunks";
import {useDispatch, useSelector} from "react-redux";
import {GlobalContext} from "../../index";


const GlobalDonatePage = injectIntl(({intl}) => {
    const {page, id} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.donate`));

    const params = {intl, options: ['pages', 'donate']}
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.loadItems(params))
    }, []);

    const {item} = useSelector(state => ({
        item: state.pagesReducer?.donate,
    }));
    const {language} = useContext(GlobalContext)

    return (
        <>
            <StairHeader title={`donate`} subtitle={`donate`} mt="50px"/>
            <section className="blog-wrap-layout2 bg-light-primary100 pt-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 pt-2">
                            <div className="single-departments-box-layout1 bg-white p-4">
                                <div className="single-departments-img">
                                    <img alt="single" src={item?.donation?.fileName}/>
                                </div>
                                <div className="item-content">
                                    <div className="item-content-wrap">
                                        <h3 className="item-title title-bar-primary5">{intlConverter(item?.donation?.name, language)}</h3>

                                        <p dangerouslySetInnerHTML={{
                                            __html: intlConverter(item?.donation?.description, language)
                                        }} className=""
                                           style={{textAlign: 'justify'}}></p>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className="columns small-12 medium-4"><a href="#XBVVYSRC"
                                                                          style={{display: 'none'}}>&nbsp;</a></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
});

export default React.memo(GlobalDonatePage)

