/**
 * Created by louidorpc on 11/16/20.
 */
import React, {Component} from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({text}) => <div><img src="/assets/img/map.png" width="30px"/></div>;

class SimpleMap extends Component {

    static defaultProps = {
        center: {
            lat: 18.5967897,
            lng: -72.2642124
        },
        zoom: 12
    };

    render() {
        return (
            <div className="col-md-12 card" style={{width: '100%',margin:'0'}}>
                <div style={{height: '55vh', width: '100%'}} className="pt-4 pb-4 mr-4">
                    <div id="googleMap" style={{width:"100%", height:"100%"}}></div>

                </div>
            </div>
        );
    }
}

export default SimpleMap;

{/*<GoogleMapReact*/}
    {/*bootstrapURLKeys={{key: "AIzaSyCuFiFVAfrbPYowSKL4zfZcakBUDbscwmc"}}*/}
    {/*defaultCenter={this.props.center}*/}
    {/*defaultZoom={this.props.zoom}*/}
{/*>*/}
    {/*<AnyReactComponent*/}
        {/*lat={18.5967897}*/}
        {/*lng={-72.2642124}*/}
    {/*/>*/}
{/*</GoogleMapReact>*/}