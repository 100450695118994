import React, {useContext, useEffect, useState} from "react"
import {Field} from "redux-form";
import {FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from "react-router";
import {getIntlMsg} from "../../../../../constants/utils";
import {useDispatch, useSelector} from "react-redux";
import {GlobalContext} from "../../../../index";
import swal from "sweetalert";
import {FileInputDocument} from "../../../../common/utils/FileInputDocument";


let renderDocuments = injectIntl(props => {
    const {intl, fields, meta: {error}, isAdd, touched, reset} = props

    const dispatch = useDispatch();
    const options = {options: ['categories']};

    const {lang, setLang, hideModal} = useContext(GlobalContext);

    const {item} = useSelector(state => ({
        item: state.pagesReducer.recruitmentItem,
    }));


    const resetSlider = () => {
        hideModal('slides')
        // dispatch(actions.resetItem({options: ['sliders']}))
    }


    // if (!fields.length) fields.push();
    return (
        <div className="row">
            <div className="col-sm-12">
                {fields.length ?
                    <label className="card-title"><b>Document requis</b></label> : null}
            </div>
            <div className="col-sm-12">
                {fields.map((lg, index) => (<>
                        <div className={'row'} key={index}>
                            <div className="col-sm-1 pt-2">
                                <h2 className="text-left">{index + 1}</h2>
                            </div>
                            <div className="col-sm-6">
                                <Field component={FileInputDocument} name={`${lg}file`} id={`${lg}file`} type="file"/>
                            </div>
                            <div className="col-sm-1 pt-2">
                                <h4 className="text-left">{item?.requiredDocs[index]}</h4>
                            </div>
                            <div className="col-sm-12 p-0 m-0">
                                <hr style={{borderBottom: '1px solid #0000'}}/>
                            </div>
                        </div>

                    </>
                ))}
                {error && <li className="error">{error}</li>}
            </div>
        </div>
    );
})
export default React.memo(withRouter(renderDocuments))
