import * as React from "react";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";


const Slider = ({id, title, subtitle, description, setShowM, name, index}) => {

    return (<>
                <div id={`slider-direction-${index}`} className="t-cn slider-direction">
                    <div className={`slider-content s-tb slide-${index}`}>
                        <div className="text-left title-container s-tb-c">
                            <div className="container">
                                <div className="slider-big-text">
                                    <span>{name?.frContent}</span>
                                    {name?.frContent}
                                </div>
                                <p className="slider-paragraph padding-right">{description?.frContent}</p>
                                <div className="slider-btn-area">
                                    <a href="#" className="item-btn" onClick={() => alert}>
                                        <FormattedMessage id={`app.label.donate`}/>
                                        <i className="fas fa-chevron-right" onClick={() => alert()}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
}

export default React.memo(Slider)

