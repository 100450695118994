import * as React from "react";
import {injectIntl} from "react-intl";
import useDocumentTitle from "../../../common/hooks/useDocumentTitle";
import actions from "../../../../thunks/common/coreThunks";
import {getIntlMsg} from "../../../../constants/utils";
import StairHeader from "../../../layout/common/SatairHeader";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import SectionContactForm from "./sections";


const ContactPage = injectIntl(({intl}) => {
    const {module} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.${module}`));

    const dispatch = useDispatch();
    const criteria = {options: [module]}

    const handleSubmite = values => {

        const entity = {
            options: ['MESSAGES'], action: 'save', intl: intl, item: values
        }
        dispatch(actions.saveOrUpdateItem(entity));
    }


    return (
        <>
            <div style={{height: '60vh', width: '100%'}} className="pt-4 mr-4">
                <div id="googleMap" style={{width: "100%", height: "100%",}}></div>

            </div>
            {/*<div className={'world-class'}></div>*/}
            <SectionContactForm handleSubmite={handleSubmite}/>
        </>
    )
})

export default React.memo(ContactPage)

