export default {
    "app.label.en": "English",
    "app.label.fr": "Francais",
    "app.label.ht": "Kreyòl",
    "app.label.dashboard": "Tablo rezime",
    "app.label.home": "Akèy",



    "app.label.support": "Sipò",
    "app.label.track_your_orders": "Siveye kòmand ou yo!",
};
