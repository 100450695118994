import * as React from "react";
import {injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router-dom";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../constants/utils";
import {useDispatch, useSelector} from "react-redux";
import {useContext, useEffect} from "react";
import actions from "../../../thunks/common/coreThunks";
import StairHeader from "../../layout/common/SatairHeader";
import SideBar from "./SideBar";
import {GlobalContext} from "../../index";
import pages from "../../../constants/options/_pages";
import BlogDetailPage from "../blog/details";
import RecrutementDetail from "./details";

const RecrutementList = injectIntl(({intl}) => {
        useDocumentTitle(getIntlMsg(intl, `app.label.network`));


        const dispatch = useDispatch();

        const {module, secondLevelPage, action, page, id} = useParams();
        const params = {intl, options: ['pages', 'recruitments']}


        useEffect(() => {
            dispatch(actions.loadItems(params))
        }, []);

        const {item, loading, items} = useSelector(state => ({
            items: state.pagesReducer.recruitment?.item || [],
            slides: state?.pagesReducer?.home?.item?.slider?.slides || [],
            loading: state.pagesReducer.home.loadingItem
        }));

        const {settings, language} = useContext(GlobalContext);


        return (<>
                <div style={{height: '40vh', width: '100%'}} className="pt-4 mr-4">
                    <div id="googleMap" style={{width: "100%", height: "100%",}}></div>
                </div>

                <SideBar items={items} intl={intl}/>
            </>
        );
    }
);


const RecrutementPage = injectIntl(({intl}) => {
    const {page} = useParams();

    return (
        <>
            {_onLoadPage(page)}
        </>
    );

});


function _onLoadPage(page, loading, item) {
    switch (page) {
        case pages.RECRUTEMENT_MODULE_DETAIL:
            return <RecrutementDetail/>
        default:
            return <RecrutementList/>

    }
}


export default React.memo(withRouter(RecrutementPage))

