/**
 * Created by louidorpc on 1/27/21.
 */
/**
 * Created by louidorpc on 11/30/20.
 */
import React from 'react'
import StairHeader from "../../../layout/common/SatairHeader";
import {injectIntl} from "react-intl";
import useDocumentTitle from "../../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../../constants/utils";
import {useParams} from "react-router-dom";
import ReactPlayer from 'react-player/lazy'

const CovidDetail = injectIntl(({intl}) => {

    return (
        <>
            <section class="single-departments-box-layout1 sigle-department-data bg-light-primary100 pt-3">
                <div class="container bg-white">
                        <div class="tab-content">
                            <div role="tabpanel" class="tab-pane fade active show" id="department1">
                                <div class="single-departments-box-layout1">
                                    <h3 class="item-title title-bar-primary5  pt-3">
                                        <div class="form-group text-center float-right">
                                            <a href="https://covid.zanmilasante.org/request"
                                               target="_blank" class="btn item-btn button-glow"
                                               style={{fontSize: '16px', background: '#ff6900', color: "#FFFF"}}>
                                                {getIntlMsg(intl, 'app.label.covid_request')} <i
                                                class="fas fa-chevron-right"></i></a>
                                        </div>
                                        {getIntlMsg(intl, 'app.label.covid_title')}
                                    </h3>
                                    <div className="item-content">
                                        <div className="item-content-wrap">
                                            <div className="oval">
                                                <div className="oval2">
                                                    <ReactPlayer url={[
                                                        {src: '/assets/1.mp4', type: 'video/mp4'}
                                                    ]}
                                                                 playing={false}
                                                                 muted="true"
                                                                 className="embed-responsive-item "
                                                                 width="100%"
                                                                 height="100%"
                                                                 controls="true"
                                                    />
                                                </div>
                                            </div>

                                            <div dangerouslySetInnerHTML={{
                                                __html: intl.formatMessage(
                                                    {id: 'app.label.about_covid'},
                                                    {value: '<strong>STRONG</strong>'}
                                                )
                                            }} style={{textAlign: 'justify', paddingTop: '30px'}}
                                                 className="mr-4 pclass"></div>


                                            <div className="item-content">
                                                <div className="item-content-wrap">
                                                    <ul class="department-info deps">
                                                        <li><span style={{}}>{getIntlMsg(intl, 'app.label.cs1')}</span>
                                                        </li>
                                                        <br></br> <br></br>
                                                        <li>{getIntlMsg(intl, 'app.label.cs2')}</li>
                                                        <br></br> <br></br>
                                                        <li>{getIntlMsg(intl, 'app.label.cs3')}</li>
                                                        <br></br> <br></br>
                                                        <li>{getIntlMsg(intl, 'app.label.cs4')}</li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="item-cost">
                                                    <h4 class="item-title title-bar-primary7">{getIntlMsg(intl, 'app.label.covid_title_stat1')}</h4>
                                                    <ul>
                                                        <li>{getIntlMsg(intl, 'app.label.statht1')}</li>
                                                        <li>{getIntlMsg(intl, 'app.label.statht2')}</li>
                                                        <li>{getIntlMsg(intl, 'app.label.statht3')}</li>
                                                        <li>{getIntlMsg(intl, 'app.label.statht4')}</li>
                                                        <li>{getIntlMsg(intl, 'app.label.statht5')}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="item-cost">
                                                    <h4 class="item-title title-bar-primary7">{getIntlMsg(intl, 'app.label.covid_title_stat2')}</h4>
                                                    <ul>
                                                        <li>{getIntlMsg(intl, 'app.label.statzl1')}</li>
                                                        <li>{getIntlMsg(intl, 'app.label.statzl2')}</li>
                                                        <li>{getIntlMsg(intl, 'app.label.statzl3')}</li>
                                                        <li>{getIntlMsg(intl, 'app.label.statzl4')}</li>
                                                        <li>{getIntlMsg(intl, 'app.label.statzl5')}</li>
                                                        <li>{getIntlMsg(intl, 'app.label.statzl6')}</li>
                                                        <li>{getIntlMsg(intl, 'app.label.statzl7')}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="item-cost">
                                                    <h4 class="item-title title-bar-primary7">{getIntlMsg(intl, 'app.label.covid_title_stat3')}</h4>
                                                    <ul>
                                                        <li>{getIntlMsg(intl, 'app.label.statTzl1')}</li>
                                                        <li>{getIntlMsg(intl, 'app.label.statTzl2')}</li>
                                                        <li>{getIntlMsg(intl, 'app.label.statTzl3')}</li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="isotope-wrap">
                                <div class="row featuredContainer zoom-gallery">
                                    <div class="col-lg-4 col-md-6 col-sm-6 col-12 cardiology orthopaedics">
                                        <div class="gallery-box-layout1" style={{height: '300px'}}>
                                            <img src="/assets/img/covid/11.jpeg" alt="gallery" class="img-fluid"/>
                                            <div class="item-icon">
                                                <a href="/assets/img/covid/11.jpeg" class="popup-zoom"
                                                   data-fancybox-group="gallery" title="">
                                                    <i class="flaticon-search"></i>
                                                </a>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6 col-sm-6 col-12 dental eye">
                                        <div class="gallery-box-layout1" style={{height: '300px'}}>
                                            <img src="/assets/img/covid/3.JPEG" alt="gallery" class="img-fluid"/>
                                            <div class="item-icon">
                                                <a href="/assets/img/covid/3.JPEG" class="popup-zoom"
                                                   data-fancybox-group="gallery" title="">
                                                    <i class="flaticon-search"></i>
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6 col-12 cardiology orthopaedics">
                                        <div class="gallery-box-layout1" style={{height: '300px'}}>
                                            <img src="/assets/img/covid/5.jpeg" alt="gallery" class="img-fluid"/>
                                            <div class="item-icon">
                                                <a href="/assets/img/covid/5.jpeg" class="popup-zoom"
                                                   data-fancybox-group="gallery" title="">
                                                    <i class="flaticon-search"></i>
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6 col-12 cardiology orthopaedics">
                                        <div class="gallery-box-layout1" style={{height: '300px'}}>
                                            <img src="/assets/img/covid/7.jpeg" alt="gallery" class="img-fluid"/>
                                            <div class="item-icon">
                                                <a href="/assets/img/covid/7.jpeg" class="popup-zoom"
                                                   data-fancybox-group="gallery" title="">
                                                    <i class="flaticon-search"></i>
                                                </a>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6 col-sm-6 col-12 cardiology orthopaedics">
                                        <div class="gallery-box-layout1" style={{height: '300px'}}>
                                            <img src="/assets/img/covid/4.jpeg" alt="gallery" class="img-fluid"/>
                                            <div class="item-icon">
                                                <a href="/assets/img/covid/4.jpeg" class="popup-zoom"
                                                   data-fancybox-group="gallery" title="">
                                                    <i class="flaticon-search"></i>
                                                </a>
                                            </div>

                                        </div>
                                    </div>


                                    <div class="col-lg-4 col-md-6 col-sm-6 col-12 cardiology orthopaedics">
                                        <div class="gallery-box-layout1" style={{height: '300px'}}>
                                            <img src="/assets/img/covid/6.jpeg" alt="gallery" class="img-fluid"/>
                                            <div class="item-icon">
                                                <a href="/assets/img/covid/6.jpeg" class="popup-zoom"
                                                   data-fancybox-group="gallery" title="">
                                                    <i class="flaticon-search"></i>
                                                </a>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6 col-sm-6 col-12 cardiology orthopaedics">
                                        <div class="gallery-box-layout1" style={{height: '300px'}}>
                                            <img src="/assets/img/covid/8.jpeg" alt="gallery" class="img-fluid"/>
                                            <div class="item-icon">
                                                <a href="/assets/img/covid/8.jpeg" class="popup-zoom"
                                                   data-fancybox-group="gallery" title="">
                                                    <i class="flaticon-search"></i>
                                                </a>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6 col-sm-6 col-12 cardiology orthopaedics">
                                        <div class="gallery-box-layout1" style={{height: '300px'}}>
                                            <img src="/assets/img/covid/10.jpeg" alt="gallery" class="img-fluid"/>
                                            <div class="item-icon">
                                                <a href="/assets/img/covid/10.jpeg" class="popup-zoom"
                                                   data-fancybox-group="gallery" title="">
                                                    <i class="flaticon-search"></i>
                                                </a>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6 col-sm-6 col-12 dental cardiology">
                                        <div class="gallery-box-layout1" style={{height: '300px'}}>
                                            <img src="/assets/img/covid/1.JPG" alt="gallery" class="img-fluid"/>
                                            <div class="item-icon">
                                                <a href="/assets/img/covid/1.JPG" class="popup-zoom"
                                                   data-fancybox-group="gallery" title="">
                                                    <i class="flaticon-search"></i>
                                                </a>
                                            </div>

                                        </div>
                                    </div>


                                </div>
                            </div>


                        </div>

                </div>
            </section>
        </>
    )
})
export default React.memo(CovidDetail)
