import * as React from "react";
import {injectIntl} from "react-intl";
import useDocumentTitle from "../../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../../constants/utils";
import {useParams} from "react-router-dom";
import StairHeader from "../../../layout/common/SatairHeader";


const ResourcesPage = injectIntl(({intl}) => {
    const {page} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.${page}`));

    return (
        <section className="team-wrap-layout3 bg-light-accent100">
            <div className="container">
                <StairHeader title={`clinic_resources`} subtitle={`covid19_resources`} mt="50px"/>
            </div>
        </section>
    );
});

export default React.memo(ResourcesPage)

