import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {getIntlMsg} from "../../../constants/utils";
import {Link, useParams, withRouter} from "react-router-dom";
import actions from "../../../thunks/common/coreThunks";
import {useDispatch, useSelector} from "react-redux";
import pages from "../../../constants/options/_pages";
import {sectionPartners} from "../home/sections"
import {useEffect} from "react";
import DefaultPage from './itemList'
import BlogDetailPage from './details'


const BlogPage = injectIntl(({intl}) => {
    const {page} = useParams();

    return (
        <>
        {_onLoadPage(page)}
        {/*{sectionPartners('')}*/}
        </>
    );

});


function _onLoadPage(page, loading, item) {
    switch (page) {
        case pages.BLOG_MODULE_DETAIL:
            return <BlogDetailPage/>
        default:
            return <DefaultPage/>

    }
}

export default React.memo(withRouter(BlogPage))

