export const menu = [
    {
        "title": "about",
        "items": [
            {"url": "#", "label": "about"},
        ]
    },
    {
        "title": "network",
        "items": [
            {"url": "#", "label": "network"},
        ]
    },
    {
        "title": "sectors",
        "items": [
            {"url": "#", "label": "sectors"},
        ]
    },
    {
        "title": "news",
        "items": [
            {"url": "#", "label": "posts"},
        ]
    }

]


export const socialItems = [
    {"url": "https://www.facebook.com/zanmi-lasante", "icon": "fab fa-facebook-f"},
    {"url": "https://www.twitter.com/zanmi-lasante", "icon": "fab fa-twitter"},
]


export const settings = {
    "companyLogo": "newlogo2.png",
    "companySchedule": "Mon - Fri: 9.00am - 11.00pm",
    "companyAbout": "La mission de Partners In Health (PIH) est d’offrir une option de soins de santé préférentielle aux pauvres. Grâce à des relations à long terme avec des organisations locales, telles que Zanmi Lasante (ZL) en Haïti, PIH/ZL.",
    "companyContact": {
        "phone": "+509 2813-0016",
        "address": "Santo 18A, Croix-des-Bouquets",
        "email": "info@zanmilasante.org",
    }

}