import * as React from "react";
import {FormattedMessage} from "react-intl";


const StairHeader = ({title, subtitle, mt = '150px'}) => {

    return (
        <section class="inner-page-banner bg-common inner-page-top-margin"
                 data-bg-image="/assets/img/brand/1.jpg"
                 style={{backgroundImage: " url(&quot;/assets/img/brand/1.jpg&quot;)"}}>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="breadcrumbs-area">
                            <h1 className="text-white"><FormattedMessage id={`app.label.${title}`} defaulMessage=""/>
                            </h1>
                            <ul>
                                <li>
                                    <a href="/" className="text-white"><FormattedMessage id={`app.label.home`} defaulMessage=""/></a>
                                </li>
                                <li className="text-white"><FormattedMessage id={`app.label.${subtitle}`} defaulMessage=""/></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default React.memo(StairHeader)

