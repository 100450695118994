import * as React from "react";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";

const Footer = ({title, items}) => {

    return (
        <div className="single-item col-lg-2 col-md-6 col-12">
            <div className="footer-box">
                <div className="footer-header">
                    <h3>
                        <FormattedMessage id={`app.label.${title}`}/>
                    </h3>
                </div>
                <div className="footer-departments">
                    <ul>
                        {items.map((item, i) =>
                            <li key={i}>
                                <a href={item.url}>
                                    <FormattedMessage id={`app.label.${item.label}`}/>
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Footer)

