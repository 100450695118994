/**
 * Created by louidorpc on 10/29/20.
 */
import React, {useContext} from 'react';
import Map from '../../map';
import {FacebookPage} from '../../facebook';
import ContactForm from './form';
import {FormattedMessage} from "react-intl";
import {GlobalContext} from "../../../../index";

const SectionContactForm = ({handleSubmite}) => {

    const onSubmit = values => {
        console.log("************************", values)
        alert(values && values.subject)
    }

    const {settings} = useContext(GlobalContext)

    return (
        <section class="contact-wrap-layout pt-4">
            <div class="container">

                <div class="row">
                    {/*<div class="col-md-12"><Map/></div>*/}
                    <div class="col-md-8">
                        <div className='world-class'></div>
                        <a href="#AJDEFYWC" style={{display: "block"}}>ssssssssssssssss</a>

                        <div class="contact-box-layout1">
                            <h3 class="title title-bar-primary4 m-4">
                                <FormattedMessage id={'app.label.contact_title'}
                                                  defaultMessage={`Envoyez-nous un message`}/></h3>
                            <ContactForm onSubmit={handleSubmite}/>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card">
                            <div className="row m-2">
                                <div className="col-md-12">
                                    <div class="contact-box-layout1">
                                        <h3 class="title title-bar-primary4">Adresse</h3>
                                        <div class="contact-info">
                                            <ul>
                                                <li><i
                                                    className="fas fa-map-marker-alt"></i>{settings?.addresses?.join(' / ')}
                                                </li>
                                                <li><i className="far fa-envelope"></i>{settings?.emails?.join(' / ')}
                                                </li>
                                                <li><i className="fas fa-phone"></i>{settings?.phones?.join(' / ')}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12 pt-0">
                                <div class="contact-box-layout1">
                                    <h3 class="title title-bar-primary4">Facebook</h3>
                                    <div class="contact-info">
                                        <FacebookPage/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionContactForm