import * as React from "react";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import Thumbnail from "./Thumbnail";
import StairHeader from "../../../../layout/common/SatairHeader";
import Pagination from "../../../../common/view/Pagination";
import Spinner from "../../../../common/utils/Spinner";


const SiteStair = ({loading, sites}) => {

    return (<>
        <div></div>
        <div style={{height: '40vh', width: '100%'}} className="pt-4 pb-4 mr-4">
            <div id="googleMap" style={{width: "100%", height: "100%",}}></div>

        </div>

        <section class="team-wrap-layout2 bg-light-accent100 pt-4">
            <div className="container">
                <div class="section-heading heading-dark text-center heading-layout4">
                    <h2>Nos différents Sites</h2>
                    <p className="justify-content-center" style={{textAlign: 'justify'}}>
                        Zanmi Lasante est l’un des plus grands fournisseurs de soins de santé en Haïti. En partenariat
                        avec le Ministère de la Santé Publique et de la Population, Zanmi Lasante fournit des soins
                        primaires, des services VIH et de traitement de la tuberculose, ainsi que des soins secondaires
                        et tertiaires à travers un réseau d'établissements de santé publique. Le réseau Zanmi Lasante
                        compte 15 sites situés dans le Plateau Central et l’Artibonite.
                    </p>
                </div>

                <div className="isotope-wrap">
                    <div className="text-center">
                        <div className="isotope-classes-tab isotop-btn">
                            <a href="#" className="current nav-item" data-filter="*">
                                <FormattedMessage id={`app.label.all`}/>
                            </a>
                            <a href="#" className="nav-item" data-filter=".pt_central">
                                <FormattedMessage id={`app.label.pt_central`}/>
                            </a>
                            <a href="#" className="nav-item" data-filter=".artibonite">
                                <FormattedMessage id={`app.label.artibonite`}/>
                            </a>
                        </div>
                    </div>
                    {sites && sites.length > 0 ?
                        <div className="row featuredContainer" id="no-equal-gallery">
                            {sites && sites.length > 0 ? sites.map((site, i) =>
                                    <Thumbnail {...site} key={i}/>
                                ) : null}
                        </div> : null}
                </div>
            </div>
        </section>
        </>
    )
}

export default React.memo(SiteStair)

