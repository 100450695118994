import * as React from "react";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";


const Thumbnail = ({id, avatar, acronym, description, address, phone, email, department}) => {

    return (
        <div className={`no-equal-item col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12  ${department}`}>
            <div className="team-box-layout1">
                <div className="media media-none-lg media-none-md media-none--xs">
                    <div className="item-img" width="400px" height="400px">
                        <img src={`/assets/${avatar}`} alt="Team1" className="img-fluid rounded-circle media-img-auto"
                        />
                    </div>

                    <div className="media-body">
                        <div className="item-content">
                            <h3 className="item-title mb-0">
                                <Link to="#">
                                    {acronym}
                                </Link>
                            </h3>
                            <p className="designation">
                                {description}
                            </p>
                            <div className="item-degree">
                                <span>{address}</span>
                                <div>{`${email} - ${phone}`}</div>
                            </div>
                            <ul className="item-btns">
                                <li>
                                    <a href={`/network/sites/${id}`} className="item-btn btn-ghost btn-block">
                                        <FormattedMessage id={`app.label.details`}/>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Thumbnail)

