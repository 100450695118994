import * as React from "react";
import pages from "../../../constants/options/_pages";
import {useParams, withRouter} from "react-router";
import OriginPage from "./contact";
import FaqPage from "./faq";
import {Faqs} from '../../../constants/options/data'


const HelpPageManager = () => {
    const {page} = useParams();
    return _onLoadPage(page)
}


function _onLoadPage(page) {
    switch (page) {
        case pages.HELP_MODULE_FAQ:
            return <FaqPage Faqs={Faqs}/>
        case pages.HELP_MODULE_CONTACT:
            return <OriginPage/>
        default:
            return null;

    }
}

export default React.memo(withRouter(HelpPageManager))

