import * as React from "react";
import {useParams, withRouter} from "react-router";
import {FormattedMessage, injectIntl} from "react-intl";
import {useContext, useEffect} from "react";
import {getRequestValues} from "../../../constants/options/subheaderValues";
import AsyncInputSearch from "../../common/utils/AsyncInputSearch";
import {getIntlMsg, intlConverter} from "../../../constants/utils";
import {GlobalContext} from "../../index";
import Select from 'react-select';
import {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import actions from "../../../thunks/common/coreThunks";


const AdminSubHeader = injectIntl(props => {

    const {intl, items, handleSetModal, language} = props
    const {btnTitle, icon} = getRequestValues;
    const {module, page} = useParams();

    const options = {options: ['pages', 'recruitments']};

    console.log("===================((", btnTitle)

    const currentView = page === 'notification' ? 'notification-form' : page === 'reception' ? 'registration' : '';

    const handleSeachRecruitmentByCategoryId = (e) => {
        // /recruitments/category/{idCategory}
        console.log("zzzzzzzzzzzzzzzzzzzz", e)
    }

    const optionss = [
        {label: 'Swedish', value: 'sv'},
        {label: 'English', value: 'en'},

    ];


    const style = {
        blockquote: {
            fontStyle: 'italic',
            fontSize: '12px',
            margin: '1rem 0',
        },
        label: {
            fontSize: '12px',
            fontWeight: 'bold',
            lineHeight: 2,
        },
    };
    const [categorySelect, setCategorySelect] = useState('');
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(actions.loadItems({options: ['categories', 'recruitment', 'active']}));
    }, [dispatch]);

    const handleSearchCategory = (e) => {
        setCategorySelect(e.label)
        let params = null;
        if (e.value === 'all') {
            params = {intl, options: ['pages', 'recruitments']}
            dispatch(actions.loadItems(params))
        } else {
            params = {intl, options: ['recruitments', 'category'], item: {id: e.value}}
            dispatch(actions.loadItem(params))
        }

    }

    const {categories} = useSelector(state => ({
        categories: state.pagesReducer?.categories || [],
    }));


    const formattedCats = [];
    formattedCats.push({value: 'all', label: getIntlMsg(intl, 'app.label.allc')})
    categories.forEach(category => {
        formattedCats.push({value: category?.id, label: intlConverter(category?.name, language),})
    })


    return (

        <div className="team-search-box">
            <div className="row">
                <div className="col-xl-12">
                    <label style={style.label} id="aria-label" htmlFor="aria-example-input">
                        <FormattedMessage id={'app.label.category_rec'}/> / {categorySelect}
                    </label>
                </div>

                <div className="col-xl-4">

                    <div className="form-group">
                        <Select
                            aria-labelledby="aria-label"
                            inputId="aria-example-input"
                            name="aria-live-color"
                            options={formattedCats && formattedCats.map((item, i) => ({
                                label: item?.label,
                                value: item?.value
                            }))}
                            className={'doctor-name form-control'}
                            onChange={(e) => handleSearchCategory(e)}
                            placeholder={getIntlMsg(intl, 'app.label.searchByCategory')}
                        />

                    </div>
                </div>
                <div className="col-xl-6">
                    <div className="form-group">
                        <AsyncInputSearch {...{options}}/>
                    </div>
                </div>
                <div className="col-xl-2">
                    <div className="form-group">
                        <button type="submit" className="item-btn">{getIntlMsg(intl, 'app.label.searchc')}</button>
                    </div>
                </div>
            </div>
        </div>


    )
})

export default React.memo(withRouter(AdminSubHeader))

