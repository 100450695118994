import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router-dom";
import Thumbnail from "./Thumbnail";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import actions from "../../../../thunks/common/coreThunks";
import useDocumentTitle from "../../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../../constants/utils";
import StairHeader from "../../../layout/common/SatairHeader";
import {Sectors} from '../../../../constants/options/data'


const SectorList = injectIntl(({intl}) => {
    const {module} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.${module}`));
    const dispatch = useDispatch();
    const criteria = {options: ['pages', 'sector']}


    useEffect(() => {
        dispatch(actions.loadItems(criteria));
    }, [dispatch]);

    const {loading, items} = useSelector(state => ({
        loading: state.pagesReducer.sector.loadingItem,
        items: state.pagesReducer.sector.items,
    }));


    return (
        <>
            <StairHeader title={`sectors`} subtitle={`sectors`} mt="50px"/>
            <section className="departments-wrap-layout8 pt-5">
                <div className="container">

                    <div class="section-heading heading-dark text-center heading-layout4">
                        <h2><FormattedMessage id={'app.label.sector_dif'}/> </h2>
                    </div>

                    <div className="row">
                        {items && items?.sectors?.map((item, i) =>
                            <Thumbnail {...item} key={i}/>
                        )}
                    </div>
                </div>
            </section>
        </>


    )
})

export default React.memo(withRouter(SectorList))

