import * as coreActionCreator from "./_coreActionCreator";
import * as proxyActionCreator from "./_proxyActionCreator";
import * as settingsActionCreator from "./_settingsActionCreator";
import * as userActionCreator from "./_userActionCreator";
import * as notificationActionCreator from "./_notificationActionCreator";

export default {
    ...coreActionCreator,
    ...settingsActionCreator,
    ...userActionCreator,
    ...proxyActionCreator,
    ...notificationActionCreator
};
