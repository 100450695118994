import React, {useState, useEffect, useContext} from "react";
import {Link, NavLink} from "react-router-dom";
// import {settings, socialItems} from "../../../constants/options/footer";
import {FormattedMessage} from "react-intl";
import {header} from "../../../constants/options/header";
import {PayPalButton} from "react-paypal-button-v2";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import {useParams, withRouter} from "react-router-dom";
import {DonateModal} from '../../../components/pages/donate/sections/donateModal';
import {ThemeProvider} from '@livechat/ui-kit';
import {GlobalContext} from "../../index";


const TopBar = ({handleChangeLanguage, language, history}) => {

    const p = history.location.pathname;
    const path = p.split('/')[1]
    const path1 = p.split('/')[2]
    const {settings} = useContext(GlobalContext);


    const paymentHandler = (details, data) => {
        console.log(details, data);
    }


    const [state, setState] = useState({
        lang: language,
        label: 'Francais', show: false
    });

    const langs = [
        {value: 'fr', label: 'Francais', logo: "/assets/img/fr.png"},
        {value: 'en', label: 'Anglais', logo: "/assets/img/en.png"}
    ]

    function setShowM() {
        setState({
            show: true, lang: language,
            label: 'Francais'
        })
    }

    function hide() {
        setState({
            show: false, lang: language,
            label: 'Francais'
        })
    }

    const {lang, label, show} = state;


    const handleChangeLang = e => {

        const val = e.target.value;
        let item = langs.find(v => v.value === val);
        setState({lang: item.value, label: item.label})
        handleChangeLanguage(val)
    }

    return (<>

            <header id="header_1">
                <div className="header-top-bar bg-primary100 d-none d-md-block">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 col-lg-9 col-md-12 col-12 header-contact-layout3">
                                <ul>
                                    <li>
                                        <i className="fas fa-map-marker-alt"/>
                                        <span> {settings?.addresses?.join(' / ')}</span>
                                    </li>
                                    <li>
                                        <i className="fas fa-phone"/>
                                        <a href={`tel:${settings?.phones[0]}`} className="text-white">
                                            <span>{settings?.phones?.join(' / ')}</span>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                            <div className="col-xl-4 col-lg-3 col-md-12 col-12 header-social-layout2">
                                <ul>
                                    {settings?.socialLinks?.map((item, i) =>
                                        <li key={i}>
                                            <a href={item.link} target="_blank">
                                                <i className={item.type === 'FACEBOOK' ? `fab fa-facebook-f` : item.type === 'TWITTER' ? 'fab fa-twitter' : item.type === 'INSTAGRAM' ? 'fab fa-instagram' : ''}/>
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-menu-area header-menu-layout4">
                    <div className="container">
                        <div className="row no-gutters d-flex align-items-center">
                            <div className="col-lg-1 logo-area-layout1">
                                <a href="/" className="temp-logo">
                                    {settings?.logos?.map(v => {
                                        if (v.type?.name?.frContenth === "Covid" && history.location.pathname === "/covid19") {

                                            return (
                                                <img
                                                    src={v.fileName ? v.fileName : 'entityType/assets/img/covidlogo.png'}
                                                    alt="logo" className="img-fluid"
                                                    data-covid="ok"
                                                    width="200px"/>)
                                        } else if (v.type?.name?.frContent === "Header" && history.location.pathname !== "/covid19") {
                                            return (
                                                <img src={`${v?.fileName}`} alt="logo"
                                                     className="img-fluid"
                                                     data-covid="none"
                                                     width="100px"/>
                                            )
                                        }
                                    })}

                                </a>
                            </div>

                            <div className="col-lg-8 possition-static">
                                <div className="template-main-menu">
                                    <nav id="dropdown">

                                        <ul>
                                            <li>
                                                <a href="/" className={p === '/' ? 'menu_active' : ''}>
                                                    <FormattedMessage id={`app.label.home`}/>
                                                </a>
                                            </li>
                                            {header.menuItems.map((item, i) => {

                                                if (item.menu == "recrutement") {
                                                    return (
                                                        <li key={i}
                                                        >
                                                            <a href="https://covid.zanmilasante.org/request"
                                                               target="_blank"
                                                               style={{color: 'red'}}
                                                               className={'app.label.' + path === 'app.label.' + item.menu || p === 'app.label.' + item.menu ? 'menu_active' : ''}
                                                            >
                                                                <FormattedMessage id={`app.label.${item.menu}`}/> <span
                                                                class="fa fa-arrow-circle-right"
                                                                style={{color: 'red'}}></span>
                                                            </a>
                                                        </li>
                                                    )
                                                } else {
                                                    return (
                                                        <li key={i}
                                                        >
                                                            <a href={item.url}
                                                               className={'app.label.' + path === 'app.label.' + item.menu || p === 'app.label.' + item.menu ? 'menu_active' : ''}
                                                            >
                                                                <FormattedMessage id={`app.label.${item.menu}`}/>
                                                            </a>
                                                            <ul className="dropdown-menu-col-1">
                                                                {item.items.map((option, j) =>
                                                                    <li key={j}>
                                                                        <a href={option.url}>
                                                                            <FormattedMessage
                                                                                id={`app.label.${option.label}`}/>
                                                                        </a>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </li>)
                                                }
                                            })}

                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="header-action-items-layout1">
                                    <ul>
                                        {/*<li className="d-none d-xl-block">*/}
                                        {/*<form id="top-search-form" className="header-search-dark">*/}
                                        {/*<input type="text" className="search-input" placeholder="Search...."*/}
                                        {/*required=""/>*/}
                                        {/*<button className="search-button">*/}
                                        {/*<i className="flaticon-search"/>*/}
                                        {/*</button>*/}
                                        {/*</form>*/}
                                        {/*</li>*/}
                                        <li className="m_left">
                                            {/*<PayPalButton*/}
                                            {/*amount={200}*/}
                                            {/*currency={'USD'}*/}
                                            {/*onSuccess={paymentHandler}*/}
                                            {/*options={{*/}
                                            {/*clientId: "AY7oTgnjHuYl7AlK8S0Ghhlr5cmFHaiqSuc1GevS_U_k62TTVu2rRQCGNXJ_3wFrM9guSSHMb3dQCRBO"*/}
                                            {/*}}*/}
                                            {/*/>*/}
                                            {/*<a className="action-items-primary-btn ml-3 glow-on-hover"*/}
                                            {/*   onClick={setShowM} style={{zIndex: 9}}>*/}
                                            {/*    <FormattedMessage id={`app.label.donate`}/>*/}
                                            {/*</a>  */}
                                            {/* <a className="action-items-primary-btn ml-3 glow-on-hover"*/}
                                            {/*  href={'/donate'} style={{zIndex: 9}}>*/}
                                            {/*     <FormattedMessage id={`app.label.donate`}/>*/}
                                            {/* </a>*/}
                                            <a className="action-items-primary-btn ml-3 glow-on-hover"
                                               href={'?form=Haiti-Earthquake-ZL'} style={{zIndex: 9}}>
                                                <FormattedMessage id={`app.label.donate`}/>
                                            </a>
                                        </li>

                                        <li className="m_right">
                                            <TextField
                                                id="outlined-select-currency"
                                                select
                                                value={lang}
                                                style={{
                                                    fontSize: '16px',
                                                    color: '#000',
                                                    position: 'relative',
                                                }}
                                                onChange={handleChangeLang}
                                            >
                                                {langs.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}
                                                              style={{
                                                                  fontSize: '16px',
                                                                  color: '#000',
                                                                  position: 'relative',
                                                              }}>
                                                        <img src={option.logo} width="20"></img>
                                                    </MenuItem>
                                                ))}
                                            </TextField>


                                        </li>


                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {show ? <DonateModal sh={show} hide={hide}/> : null}

        </>
    );
};

export default React.memo(withRouter(TopBar))
