import * as React from "react";
import {NavLink, withRouter, useParams} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import {useContext} from "react";
import {GlobalContext} from "../../index";
import {intlConverter} from "../../../constants/utils";
import Item from "./Item";
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import AdminSubHeader from "../subheader/adminSubheader";

const SideBar = ({value, lang}) => {
    const {module, page, id} = useParams();
    const {language} = useContext(GlobalContext)

    return (<>
            <div class="row">

                <div className="col-xl-12 col-lg-12">

                    <div className="tab-content">
                        <h3 className="section-title  title-bar-primary font-weight-bold">
                            <FormattedMessage
                                id={'app.label.research'}/></h3>

                        <div className="col-xl-12 col-lg-12 col-12 bg-white">
                            <div className="blog-detail-box pt-4 pb-4">
                                <h3 className="item-title bold">
                                    <b dangerouslySetInnerHTML={{
                                        __html: intlConverter(value?.name, language)
                                    }} className="justify-content-center"
                                       style={{textAlign: 'justify',textJustify:'inter-character'}}></b>
                                </h3>
                                <div className="single-departments-box-layout1">
                                    <div className="item-content">
                                        <p dangerouslySetInnerHTML={{
                                            __html: intlConverter(value?.description, language)
                                        }} className="justify-content-center"
                                           style={{textAlign: 'justify',textJustify:'inter-character'}}></p>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </>

    )
}

export default React.memo(withRouter(SideBar))

