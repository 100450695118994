import React, {useEffect, useState} from "react";
import {intlConverter} from "../../../constants/utils";
import {FormattedMessage} from "react-intl";
import {ComponentModal} from './ComponentModal'
import moment from "moment";
import 'moment/locale/fr'
import 'moment/locale/en-au'

const Item = ({item, language, page, index}) => {

    moment.locale(language)

    const [show, setShow] = useState(false)

    const handleShow = () => {
        setShow(true)

    }

    const handleHide = () => {
        setShow(false)
    }

    const calculateTimeLeft = () => {
        let year = new Date().getFullYear();
        const m = moment(item?.dateEnded).format("MM");
        const d = moment(item?.dateEnded).format("DD");
        const y = moment(item?.dateEnded).format("YYYY");

        let difference = +new Date(`${m}/${d}/${y}`) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                j: Math.floor(difference / (1000 * 60 * 60 * 24)),
                h: Math.floor((difference / (1000 * 60 * 60)) % 24),
                mn: Math.floor((difference / 1000 / 60) % 60),
                s: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;

    }


    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    });


    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
            return;
        }
        timerComponents.push(
            <span style={{fontSize: '12px'}}>
      {timeLeft[interval]} {interval}{" "}
    </span>
        );
    });


    return (
        <>
            <div className="col-lg-6 col-md-6">
                <div className="team-box-layout1" style={{padding: '25px 30px 25px 30px'}}>
                    <div className="media media-none-lg media-none-md media-none--xs">
                        <div className="media-body" style={{marginLeft: '0px'}}>
                            <div className="item-content">

                                <h4 className="item-title">
                                    <a href="single-doctor.html"> {intlConverter(item.name, language)}</a>
                                    <p className="designation" style={{fontWeight: 'normal', color: '#909090'}}>
                                        <FormattedMessage id={'app.label.category_rec'}
                                                          defaultMessage={'Category'}/> / {intlConverter(item?.category?.name, language)}
                                    </p>
                                </h4>

                                <p className="designation pb-4 ellipse"
                                   style={{margin: '2px'}}> {intlConverter(item?.about, language)}</p>
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="item-degree" style={{fontSize: '13px'}}>
                                            <span style={{color: '#797575'}}><i
                                                className="fa fa-map-marker"/></span> {item?.place}
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="item-degree" style={{fontSize: '13px', textAlign: 'end'}}>
                                            <span style={{color: '#797575'}}><FormattedMessage
                                                id={'app.label.limited_date'}
                                                defaultMessage={'Date limite'}/></span> : {moment(item?.dateEnded).format('DD MMM YYYY') || 'HAITI'}<br/>
                                            <span style={{color: '#797575'}}> <i
                                                className="fa fa-clock"/></span> {timerComponents}
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="item-degree" style={{fontSize: '13px'}}>
                                            <FormattedMessage id={'app.label.required_doc'}
                                                              defaultMessage={'Documents requis'}/>:<br/>
                                            <p>   {item?.requiredDocs?.map(v => <span
                                                style={{color: '#797575', margin: '10px', fontSize: '13px'}}><i
                                                className="fa fa-folder"/> {v}</span>)}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <ul className="item-btns" style={{paddingTop: '0px'}}>
                                            <li className={'float-right'}>
                                                <a className="item-btn btn-primary btn-fill btn-lg shadow-none"
                                                   style={{cursor: 'pointer', padding: '5px'}}
                                                   href={`/jobs/details/${item?.id}`}>Appliquer
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ComponentModal sh={show} hide={handleHide} item={item} language={language}/>
        </>
    )
}

export default Item