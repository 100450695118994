import React, {useContext, useEffect} from 'react'
import AdminSubHeader from "../../subheader/adminSubheader";
import Item from "../Item";
import {intlConverter} from "../../../../constants/utils";
import DownloadLink from "react-download-link";
import actions from "../../../../thunks/common/coreThunks";
import {useDispatch, useSelector} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams} from "react-router-dom";
import {GlobalContext} from "../../../index";
import moment from "moment";
import FormComponent from './form'
import {RecruitmentModel} from "../../../../constants/models/Recruitment";
import 'moment/locale/fr'
import 'moment/locale/en-au'


const RecrutementDetail = injectIntl(({intl}) => {
    const {module, page, id} = useParams();
    const params = {intl, options: ['recruitments'], item: {id: id}}
    const dispatch = useDispatch();
    const {settings, language} = useContext(GlobalContext);

    useEffect(() => {
        dispatch(actions.loadItem(params))
    }, []);

    const {item} = useSelector(state => ({
        item: state.pagesReducer.recruitmentItem,
    }));

    moment.locale(language)

    const documentType = type => {
        switch (type) {
            case 'pdf':
            case 'PDF':
                return (
                    "/assets/img/icon/pdf.svg"
                )
            case 'csv':
                return (
                    "/assets/img/icon/csv.svg"
                )
            case 'xlsx':
                return (
                    "/assets/img/icon/xls.svg"
                )
            case 'docx':
                return (
                    "/assets/img/icon/docx.svg"
                )
            case 'jpg':
            case 'JPG':
            case 'jpeg':
            case 'JPEG':
                return (
                    "/assets/img/icon/jpg.svg"
                )
            case 'png':
            case 'PNG':
                return (
                    "/assets/img/icon/png.svg"
                )
            default:
                return (
                    "/assets/img/icon/doc.svg"
                )
        }
    }

    const getDataFromURL = (url) => new Promise((resolve, reject) => {
        setTimeout(() => {
            fetch(url)
                .then(response => response.blob())
                .then(data => {
                    resolve(data)
                });
        });
    }, 2000);

    const handleSubmite = values => {
        const entity = new RecruitmentModel(values);
        entity.itemId = item?.id
        const params = {
            options: ['applications', 'recruitment'], action: 'save', intl: intl, item: entity
        }
        dispatch(actions.saveOrUpdateItem(params));
    }


    return (
        <React.Fragment>
            <div style={{height: '40vh', width: '100%'}} className="pt-4 mr-4">
                <div id="googleMap" style={{width: "100%", height: "100%",}}></div>
            </div>
            <section className="team-wrap-layout3 bg-light-accent100 pt-4">
                <div className="container">
                    {item && <div className="row">

                        <div className="col-md-8">
                            <div className="single-departments-box-layout1 bg-white">
                                <div className="item-content m-4">
                                    <div className="item-content-wrap pt-4 mr-4">
                                        <h4 className="item-title">
                                            <a href="#"
                                               style={{color: '#FF6900'}}> {intlConverter(item.name, language)}</a>
                                            <p className="designation" style={{fontWeight: 'normal', color: '#909090'}}>
                                                <FormattedMessage id={'app.label.category_rec'}
                                                                  defaultMessage={'Category'}/> / {intlConverter(item?.category?.name, language)}
                                            </p>
                                        </h4>
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <div className="item-degree" style={{fontSize: '14px'}}>
                                            <span style={{color: '#f88410'}}><i
                                                className="fa fa-map-marker"/></span> {item?.place}
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="item-degree" style={{fontSize: '14px'}}>
                                            <span style={{color: '#f88410'}}><FormattedMessage
                                                id={'app.label.limited_date'}
                                                defaultMessage={'Date limite'}/></span> : {moment(item?.dateEnded).format('DD MMM YYYY') || 'HAITI'}<br/>

                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="item-degree" style={{fontSize: '14px'}}>
                                                    <FormattedMessage id={'app.label.required_doc'}
                                                                      defaultMessage={'Documents requis'}/>:<br/>
                                                    <p>   {item?.requiredDocs?.map(v => <span
                                                        style={{
                                                            color: '#f88410',
                                                            marginRight: '10px',
                                                            fontSize: '14px'
                                                        }}><i
                                                        className="fa fa-folder"/> {v}</span>)}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <h5>
                                                <b><FormattedMessage id={'app.label.about'}/></b>
                                            </h5>
                                            <p className="designation pb-4"
                                               style={{margin: '2px'}}> {intlConverter(item?.about, language)}</p>
                                        </div>

                                        <div>
                                            <h5>
                                                <b><FormattedMessage id={'app.label.description'}/></b>
                                            </h5>
                                            {item?.description ? <>
                                                <p dangerouslySetInnerHTML={{
                                                    __html: intlConverter(item?.description, language)
                                                }}></p>
                                            </> : null}
                                        </div>


                                        <div className="row pb-5">
                                            {item?.documents?.length > 0 ?
                                                <div className="col-md-12">
                                                    <h4 className="item-title title-bar-primary5 pb-2">
                                                        Documents
                                                    </h4>
                                                </div> : null}
                                            {item?.documents?.map(v => (
                                                <div className="col-md-4 col-xl-4 col-4 card ml-4">
                                                    <DownloadLink
                                                        style={{textDecoration: 'none'}}
                                                        label={<div className={'row pt-3'}>
                                                            <div className={'col-md-3'}>
                                                                <img src={documentType(v?.fileName?.split('.')[2])}
                                                                     width="70px"
                                                                     height="70px"/>
                                                            </div>
                                                            <div className={'col-md-7 overflow-ellip'}>
                                                                <p style={{textDecoration: 'none'}}>{v.documentName}</p>
                                                            </div>
                                                            <div className={'col-md-1 mr-1'}>
                                                                <i className="fa fa-download"
                                                                   style={{color: '#f06810'}}></i>
                                                            </div>
                                                        </div>}
                                                        filename={v.documentName + "." + v.fileName?.split('.')[2]}
                                                        exportFile={() => Promise.resolve(getDataFromURL(v.fileName))}
                                                    />
                                                </div>))}

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <FormComponent item={item ? item : null} onSubmit={handleSubmite} intl={intl}/>

                    </div>}
                </div>
            </section>
        </React.Fragment>
    )
});

export default RecrutementDetail;