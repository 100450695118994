import * as React from "react";

export const getRequestValues = {
    title: "all_request",
    btnTitle: "new_request",
    flatUrl: {},
    hasChildren: true,
    icon: 'add_circle',
    paths: [
        {label: "traveler", url: 'registration', context:'traveler', icon: 'airplanemode_active'},
        {label: "staff", url: 'registration', context:'staff', icon: 'supervisor_account'},
    ],
}


export const getCartValues = {
    title: "all_carts",
    btnTitle: "new_cart",
    flatUrl: {},
    hasChildren: true,
    icon: 'add_circle',
    paths: [
        {label: "traveler", url: 'registration-cart', context:'traveler', icon: 'airplanemode_active'},
        // {label: "staff", url: 'registration-cart', context:'staff', icon: 'supervisor_account'},
    ],
}

export const getResultValues = {
    title: "request_option_menu",
    btnTitle: "result_menu",
    flatUrl: {},
    hasChildren: true,
    icon: 'view_carousel',
    paths: [
        {label: "archive_all", url: '#', context:'traveler', icon: 'class'},
    ],
}


export const getPatientValues = {
    title: "all_patient",
    btnTitle: "fill_sheet",
    listTitle: "all_request_for_notification",
    flatUrl: null,
    hasChildren: false,
    icon: <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"/>
            <circle fill="#000000" cx="9" cy="15" r="6"/>
            <path
                d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z"
                fill="#000000" opacity="0.3"/>
        </g>
    </svg>,
    paths: [],
}
