const dashboardTypes = {

    ABOUT_MODULE: "about",
    ABOUT_MODULE_LEADERSHIP: "leadership",
    ABOUT_MODULE_ORIGIN: "origin",
    ABOUT_MODULE_IMPACT: "impact",
    ABOUT_MODULE_FOCUS: "focus",
    ABOUT_MODULE_MISSION: "vision-mission",


    NETWORK_MODULE: "network",
    SECTOR_MODULE: "sectors",
    BLOG_MODULE: "news",
    BLOG_MODULE_DETAIL: "detail",
    NETWORK_MODULE_SITE: "sites",

    HELP_MODULE: "help",
    HELP_MODULE_FAQ: "faqs",
    HELP_MODULE_CONTACT: "contact",

    COVID19_MODULE: "covid19",
    COVID19_MODULE_RESPONSE: "response",
    COVID19_MODULE_RESOURCES: "resources",
    COVID19_MODULE_JOIN_TEAM: "join-our-team",
    SECTOR_MODULE_DETAIL: 'detail',
    RECRUTEMENT_MODULE_DETAIL: 'details',

    DONATE_MODULE: "donate",
    RECRUTEMENT_MODULE: "jobs",
    RESEARCH_MODULE: "research",


};


export default dashboardTypes;
