import * as React from "react";
import {NavLink, withRouter, useParams} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../../index";
import Item from "./Item";
import AdminSubHeader from "../subheader/adminSubheader";
import moment from "moment";

const SideBar = ({items, lang, intl}) => {
    const {module, page, id} = useParams();
    const {language} = useContext(GlobalContext)


    const calculateTimeLeft = (item) => {
        let year = new Date().getFullYear();
        const m = moment(item?.dateEnded).format("MM");
        const d = moment(item?.dateEnded).format("DD");
        const y = moment(item?.dateEnded).format("YYYY");

        let difference = +new Date(`${m}/${d}/${y}`) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                j: Math.floor(difference / (1000 * 60 * 60 * 24)),
                h: Math.floor((difference / (1000 * 60 * 60)) % 24),
                mn: Math.floor((difference / 1000 / 60) % 60),
                s: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;

    }


    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    });


    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
            return;
        }
        timerComponents.push(
            <span style={{fontSize: '18px'}}>
      {timeLeft[interval]} {interval}{" "}
    </span>
        );
    });

    return (<>
            <section className="team-wrap-layout3 bg-light-accent100 pt-4">
                <div className="container">
                    <AdminSubHeader items={items ? items : []} language={language}/>
                    <div className="row">
                        {items && items.map((item, i) =>
                            <Item item={item}
                                  language={language}
                                  page={page}/>
                        )}
                    </div>
                </div>
            </section>


        </>

    )
}


export default React.memo(withRouter(SideBar))

