import * as React from "react";
import pages from "../../../constants/options/_pages";
import {useParams, withRouter} from "react-router";
import ResponsePage from "./response";
import ResourcesPage from "./resources";
import JoinTeamPage from "./joinTeam";
import DefaultPage from "./defaultPage";


const Covid19PageManager = () => {
    const {page} = useParams();
    return _onLoadPage(page)
}


function _onLoadPage(page) {
    switch (page) {
        case pages.COVID19_MODULE_RESPONSE:
            return <ResponsePage/>
        case pages.COVID19_MODULE_RESOURCES:
            return <ResourcesPage/>
        case pages.COVID19_MODULE_JOIN_TEAM:
            return <JoinTeamPage/>
        default:
            return <DefaultPage/>;

    }
}

export default React.memo(withRouter(Covid19PageManager))

