/**
 NOTE  get internationalized text by a specific key
 *     @param intl
 *     @param key
 *     @return string
 */
import app from "./config";

export const getIntlMsg = (intl, key) => {
    return intl.formatMessage({id: key});
};


/**
 NOTE  Uppercase the first letter of a string
 *     @param  s : string
 *     @return string
 */
export const capitalize = s => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};


/**
 NOTE  Uppercase the first letter of a string
 *     @param  apiName : string
 *     @return string
 */
export const getRootUrl = apiName => {

    switch (apiName) {

        case "specimenU":
            return app.defaultRootUrl;

        case "tma":
            return app.tmaRootUrl;

        default:
            return app.defaultRootUrl;
    }
};


/**
 NOTE  Uppercase the first letter of a string
 *     @param  options : Array
 *     @param singular
 *     @return string
 */
export const typeNameFormatter = (options, singular = true) => {
    let typeName = ``

    options.forEach((element, key) => {
        let current = element;

        if (key < (options.length)) {
            current = element.endsWith('ies') && !singular ? element.replace("ies", 'y')
                : element.endsWith('s') && singular ? replaceAt(element, element.length - 1, '')
                    : element
        }
        typeName += `${current.toUpperCase().replace("-", "_").toUpperCase()}${key < (options.length - 1) ? '_' : ''}`;


    })

    return typeName;
};

const replaceAt = (element, index, replacement) => {
    return element.substr(0, index) + replacement + element.substr(index + 1)
}


export const getUserRoles = (clientId, resourceAccess) => {


    let modules = new Map(Object.entries(resourceAccess));
    let foundModule = null;
    modules.forEach((value, key) => {
        if (key === clientId)
            foundModule = value;
    });
    return foundModule.roles;
};


export const getSeparatedValues = (values, separator) => {
    let reqOptions = ''

    values.forEach((element, key) => {
        reqOptions += `${element.toString().toLowerCase()}${key < (values.length - 1) ? separator : ''}`;
    })
    return reqOptions;
};


export const userSession = ({
                                clientId,
                                tokenParsed: {resource_access},
                                idTokenParsed: {name, given_name, family_name}
                            }) => {


    return {
        roles: getUserRoles(clientId, resource_access),
        fullName: name,
        firstName: given_name,
        lastName: family_name,
    };


};


export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split("base64,")[1]);
        reader.onerror = error => reject(error);
    });
}

export const getLoadFileProperties = async (e) => {
    const targetFile = e.target.files[0]
    const fileName = targetFile.name;

    if (targetFile) {
        const val = await getBase64(targetFile)
        return val ? {
            'size': targetFile.size,
            'fileName': fileName.substring(0, fileName.lastIndexOf('.')),
            'fileType': fileName.substring(fileName.lastIndexOf('.') + 1),
            'uploadedFile': val
        } : {}
    } else {
        return null;
    }
}

export const buildReport = (categories, items) => {
    let reports = [];
    categories && categories.forEach((category) => {
        let amount = 0, customerCounter = 0;
        items && items.forEach((item) => {
            if (category.name === item.categoryName) {
                amount += item.amount;
                customerCounter++;
            }
        })
        reports.push({category: category.name, amount, customerCounter})
    });
    return reports;
};

export const intlConverter = (content, lang) => {
    switch (lang) {
        case 'fr':
            return content?.frContent
        case 'en':
            return content?.enContent
        default:
            return content?.htContent
    }
}









