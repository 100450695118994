import React from "react"
import {useDispatch} from "react-redux";
import actions from "../../../thunks/common/coreThunks";
import {injectIntl} from "react-intl";
import {getIntlMsg} from "../../../constants/utils";


const AsyncInputSearch = injectIntl(props => {
    const {options, limit = 3, labeled,intl} = props
    const dispatch = useDispatch();

    function findByCriteria(criteria) {
        if (criteria.length >= limit)
            dispatch(actions.loadItems({...options, criteria}));
        else if (criteria.length === limit - 1 || criteria.length === 0)
            dispatch(actions.loadItems({...options}));
    }


    return (

        <input className="doctor-name form-control" type="text"
               placeholder={getIntlMsg(intl, 'app.label.searchbycriteria')}
               onChange={(e) => findByCriteria(e.target.value?.toLowerCase())}/>
    )
})


export default AsyncInputSearch

