const sectorActionTypes = {

    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * SECTORS PAGE ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_SECTORS_PAGE: "LOAD_SECTORS_PAGE",
    LOAD_SECTORS_PAGE_SUCCEEDED: "LOAD_SECTORS_PAGE_SUCCEEDED",
    LOAD_SECTORS_PAGE_FAILED: "LOAD_SECTORS_PAGE_FAILED",
    LOAD_PAGES_SECTOR_PAGE_SUCCEEDED: "LOAD_PAGES_SECTOR_PAGE_SUCCEEDED",


    LOAD_BLOG_PAGE: "LOAD_BLOG_PAGE",
    LOAD_BLOG_PAGE_SUCCEEDED: "LOAD_BLOG_PAGE_SUCCEEDED",
    LOAD_BLOG_PAGE_FAILED: "LOAD_BLOG_PAGE_FAILED",
    LOAD_NEWS_PAGE_SUCCEEDED: "LOAD_NEWS_PAGE_SUCCEEDED",


    LOAD_BLOGS_PAGE: "LOAD_BLOGS_PAGE",
    LOAD_BLOGS_PAGE_SUCCEEDED: "LOAD_BLOGS_PAGE_SUCCEEDED",
    LOAD_BLOGS_PAGE_FAILED: "LOAD_BLOGS_PAGE_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * SECTOR PAGE ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_SECTOR_PAGE: "LOAD_SECTOR_PAGE",
    LOAD_SECTOR_PAGE_SUCCEEDED: "LOAD_SECTOR_PAGE_SUCCEEDED",
    LOAD_SECTOR_PAGE_FAILED: "LOAD_SECTOR_PAGE_FAILED",


    LOAD_NETWORKS_PAGE: "LOAD_NETWORKS_PAGE",
    LOAD_NETWORKS_PAGE_SUCCEEDED: "LOAD_NETWORKS_PAGE_SUCCEEDED",
    LOAD_NETWORKS_PAGE_FAILED: "LOAD_NETWORKS_PAGE_FAILED",
    LOAD_PAGES_NETWORK_PAGE_SUCCEEDED: "LOAD_PAGES_NETWORK_PAGE_SUCCEEDED",

    LOAD_PAGES_RECRUITMENTS_PAGE: "LOAD_PAGES_RECRUITMENTS_PAGE",
    LOAD_PAGES_RECRUITMENTS_PAGE_SUCCEEDED: "LOAD_PAGES_RECRUITMENTS_PAGE_SUCCEEDED",
    LOAD_RECRUITMENTS_PAGE_SUCCEEDED: "LOAD_RECRUITMENTS_PAGE_SUCCEEDED",
    LOAD_PAGES_RECRUITMENTS_PAGE_FAILED: "LOAD_PAGES_RECRUITMENTS_PAGE_FAILED",



    LOAD_NETWORK_PAGE: "LOAD_NETWORK_PAGE",
    LOAD_NETWORK_PAGE_SUCCEEDED: "LOAD_NETWORK_PAGE_SUCCEEDED",
    LOAD_NETWORK_PAGE_FAILED: "LOAD_NETWORK_PAGE_FAILED",

    SAVE_MESSAGE: "SAVE_MESSAGE",
    SAVE_MESSAGE_SUCCEEDED: "SAVE_MESSAGE_SUCCEEDED",
    SAVE_MESSAGE_FAILED: "SAVE_MESSAGE_FAILED",
    SAVE_MESSAGES_SUCCEEDED: "SAVE_MESSAGES_SUCCEEDED",
    RESET_MESSAGE: "RESET_MESSAGE",



    LOAD_HOME_PAGE: "LOAD_HOME_PAGE",
    LOAD_PAGES_HOME_PAGE_SUCCEEDED: "LOAD_PAGES_HOME_PAGE_SUCCEEDED",
    LOAD_HOME_PAGE_FAILED: "LOAD_HOME_PAGE_FAILED",


    LOAD_ABOUT_PAGE: "LOAD_ABOUT_PAGE",
    LOAD_ABOUT_PAGE_SUCCEEDED: "LOAD_ABOUT_PAGE_SUCCEEDED",
    LOAD_ABOUT_PAGE_FAILED: "LOAD_ABOUT_PAGE_FAILED",
    LOAD_PAGES_ABOUT_PAGE_SUCCEEDED: "LOAD_PAGES_ABOUT_PAGE_SUCCEEDED",


    LOAD_FAQS_PAGE: "LOAD_FAQS_PAGE",
    LOAD_FAQS_PAGE_SUCCEEDED: "LOAD_FAQS_PAGE_SUCCEEDED",
    LOAD_FAQS_PAGE_FAILED: "LOAD_FAQS_PAGE_FAILED",

    LOAD_COVID_PAGE: "LOAD_COVID_PAGE",
    LOAD_COVID_PAGE_SUCCEEDED: "LOAD_COVID_PAGE_SUCCEEDED",
    LOAD_COVID_PAGE_FAILED: "LOAD_COVID_PAGE_FAILED",

    LOAD_COMPANY_PAGE_SUCCEEDED:"LOAD_COMPANY_PAGE_SUCCEEDED",


    LOAD_PAGES_RESEARCH_PAGE:"LOAD_PAGES_RESEARCH_PAGE",
    LOAD_PAGES_RESEARCH_PAGE_SUCCEEDED:"LOAD_PAGES_RESEARCH_PAGE_SUCCEEDED",
    LOAD_PAGES_RESEARCH_PAGE_FAILED:"LOAD_PAGES_RESEARCH_PAGE_FAILED"
    ,
    LOAD_RECRUITMENT_SUCCEEDED:"LOAD_RECRUITMENT_SUCCEEDED",
    SAVE_APPLICATION_RECRUITMENT_SUCCEEDED:"SAVE_APPLICATION_RECRUITMENT_SUCCEEDED",
    RESET_RECRUITMENT:"RESET_RECRUITMENT",
    LOAD_RECRUITMENT_CATEGORY_SUCCEEDED:"LOAD_RECRUITMENT_CATEGORY_SUCCEEDED",
    LOAD_CATEGORY_RECRUITMENT_ACTIVE_PAGE_SUCCEEDED:"LOAD_CATEGORY_RECRUITMENT_ACTIVE_PAGE_SUCCEEDED",
    LOAD_PAGES_DONATE_PAGE_SUCCEEDED:"LOAD_PAGES_DONATE_PAGE_SUCCEEDED",

};


export default sectorActionTypes;