/**
 * Created by louidorpc on 11/30/20.
 */
import React, {useEffect} from 'react'
import StairHeader from "../../../layout/common/SatairHeader";
import {injectIntl} from "react-intl";
import useDocumentTitle from "../../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../../constants/utils";
import {useParams} from "react-router-dom";
import Details from './details'
import {useDispatch, useSelector} from "react-redux";
import actions from "../../../../thunks/common/coreThunks";

const DefaultPage = injectIntl(({intl}) => {

    useDocumentTitle(getIntlMsg(intl, `app.label.covid19`));

    const dispatch = useDispatch();

    const {module, secondLevelPage, action} = useParams();
    const params = {intl, options: ['covid']}


    useEffect(() => {
        dispatch(actions.loadItems(params))
    }, []);

    const {items, loading,} = useSelector(state => ({
        items: state.pagesReducer.covid.items,
        loading: state.pagesReducer.covid.loadingItems
    }));

    return (
        <>
            <StairHeader title={"covid19"} subtitle={`covid19`}/>
            <Details/>
        </>
    )
})
export default React.memo(DefaultPage)
