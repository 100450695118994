const settingActionTypes = {


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * -------------------------------------------
     * */
    LOAD_SETTING: "LOAD_SETTING",
    LOAD_SETTING_SUCCEEDED: "LOAD_SETTING_SUCCEEDED",
    LOAD_SETTING_FAILED: "LOAD_SETTING_FAILED",

    UPDATE_SETTING: "UPDATE_SETTING",
    UPDATE_SETTING_SUCCEEDED: "UPDATE_SETTING_SUCCEEDED",
    UPDATE_SETTING_FAILED: "UPDATE_SETTING_FAILED",

};

export default settingActionTypes;
