import * as React from "react";
import {injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router-dom";
import useDocumentTitle from "../../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../../constants/utils";
import {useDispatch, useSelector} from "react-redux";
import {useContext, useEffect} from "react";
import actions from "../../../../thunks/common/coreThunks";
import StairHeader from "../../../layout/common/SatairHeader";
import SideBar from "./SideBar";
import {Sites} from '../../../../constants/options/data'
import {GlobalContext} from "../../../index";

const SiteDetailPage = injectIntl(({intl}) => {
    useDocumentTitle(getIntlMsg(intl, `app.label.network`));


    const dispatch = useDispatch();

    const {module, secondLevelPage, action, page, id} = useParams();
    const params = {intl, options: ['pages', 'network']}


    useEffect(() => {
        dispatch(actions.loadItems(params))
    }, []);

    const {item, loading, slides} = useSelector(state => ({
        item: state.pagesReducer.network.item,
        slides: state?.pagesReducer?.home?.item?.slider?.slides || [],
        loading: state.pagesReducer.home.loadingItem
    }));

    const {settings, language} = useContext(GlobalContext);


    return (<>
            {/*<StairHeader title={`details`} subtitle={`page_details`} mt={`20px`}/>*/}
            <div></div>
            <div style={{height: '40vh', width: '100%'}} className="pt-4 pb-4 mr-4">
                <div id="googleMap" style={{width: "100%", height: "100%",}}></div>

            </div>
            <section class="single-department-wrap-layout1 bg-light-primary100 pt-4">
                <div class="container">


                    {item && item?.healthCenters?.length > 0 ?
                        <SideBar sites={item?.healthCenters}/>
                        : null}
                </div>
            </section>
        </>
    );
});


export default React.memo(withRouter(SiteDetailPage))

