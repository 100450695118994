import * as React from "react";
import {NavLink, withRouter, useParams} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import Map from '../../help/map'
import {FacebookPage, FacebookComment} from '../../help/facebook'
import moment from "moment";
import {intlConverter} from "../../../../constants/utils";
import {useContext} from "react";
import {GlobalContext} from "../../../index";
import 'moment/locale/fr'
import 'moment/locale/en-au'


const SideBar = ({items}) => {
    const {module, page, id} = useParams();

    const blog = items && items.find(element => element.id === id && element?.status === true);
    const {language} = useContext(GlobalContext)

    let maxNews = [];
    if (items?.length > 0)
        for (let i = 0; i < 3; i++) {
            maxNews.push(items[i]);
        }
    moment.locale(language);

    return (
        <section class="blog-detail-wrap bg-light-primary100 pt-2">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-sm-8 bg-white">
                        <div class="blog-detail-box">

                            <div class="blog-detail-title">
                                <div className="item-content pt-4">
                                    <h2 className="item-title">
                                        {intlConverter(blog.title, language)}</h2>

                                </div>
                                <div class="item-img  oval">
                                    <img src={blog && blog.fileName} class="img-fluid oval2"
                                         alt="blog" width={'100%'}/>
                                    <div class="post-date">{moment(blog.created).format('DD')}
                                        <span>{moment(blog.created).format('MMM YYYY')}</span>
                                    </div>
                                </div>

                                <div class="blog-content pt-5 pb-4">

                                    <p dangerouslySetInnerHTML={{
                                        __html: intlConverter(blog.content, language)
                                    }} className={'item-title'} style={{textAlign: 'justify'}}></p>

                                </div>
                                <div>
                                    <FacebookComment/>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="sidebar-widget-area sidebar-break-md col-md-4 col-12">

                        <div className="bg-white">
                            <div class="widget widget-recent">
                                <h3 class="section-title title-bar-primary pt-4 m-2"><FormattedMessage
                                    id={'app.label.similary_article'} defaultMessage={'Articles Similaires'}/></h3>
                                {maxNews && maxNews.length <= 3 ? maxNews.map(value => value?.status === true &&
                                    <div class="media m-2">
                                        <a href={`/news/detail/${value.id}`}>
                                            <img src={value.fileName} alt="news" class="img-fluid"
                                                 width="100"
                                                 height="100"/>
                                        </a>
                                        <div class="media-body space-sm">
                                            <span class="post-date">{moment(value.created).format('DD MMM YYYY')}</span>
                                            <h4 class="post-title">
                                                <a href={`/news/detail/${value.id}`}>{intlConverter(value.title, language)}</a>
                                            </h4>
                                        </div>
                                    </div>) : null}

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
}


export default React.memo(withRouter(SideBar))

