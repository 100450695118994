import {createBrowserHistory} from 'history'
import {applyMiddleware, createStore, compose} from "redux"
import {routerMiddleware} from 'connected-react-router'
import createRootReducer from '../reducers'
import logger from "redux-logger"
import thunk from "redux-thunk";

export const history = createBrowserHistory();

export default function configureStore(preloadedState) {

    const composeEnhancers = window.window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const middleware = [thunk, routerMiddleware(history)];

    if (process.env.NODE_ENV === "development") {
        middleware.push(logger)
    }


    return createStore(
        createRootReducer(history),
        preloadedState,
        composeEnhancers(applyMiddleware(...middleware),
        ),
    );
}