import * as React from "react";
import {injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router-dom";
import SiteStair from "./sites";
import PartnerStair from "../../blog/details";
import useDocumentTitle from "../../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../../constants/utils";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import actions from "../../../../thunks/common/coreThunks";


const DefaultPage = injectIntl(({intl}) => {
    const {module} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.network`));

    const dispatch = useDispatch();
    const criteria = {options: [module]}

    useEffect(() => {
        dispatch(actions.loadItems(criteria));
    }, [dispatch]);

    const {loading, item} = useSelector(state => ({
        loading: state.pagesReducer.network.loadingItem,
        item: state.pagesReducer.network.item,
    }));

    return (
        <SiteStair {...{loading, ...item}}/>

    );
});


export default React.memo(withRouter(DefaultPage))

