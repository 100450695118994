const proxyActionTypes = {


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * -------------------------------------------
     * */
    LOAD_TMA: "LOAD_TMA",
    LOAD_TMA_SUCCEEDED: "LOAD_TMA_SUCCEEDED",
    LOAD_TMA_FAILED: "LOAD_TMA_FAILED",

};


export default proxyActionTypes;
