import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router-dom";
import useDocumentTitle from "../../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../../constants/utils";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import actions from "../../../../thunks/common/coreThunks";
import StairHeader from "../../../layout/common/SatairHeader";
import SideBar from "./SideBar";

const PageContent = injectIntl(({intl, sites}) => {
    const {module, page, id} = useParams();
    // useDocumentTitle(getIntlMsg(intl, `app.label.${page}`));
    //
    // const dispatch = useDispatch();
    // const criteria = {options: [module]}
    //
    // useEffect(() => {
    //     dispatch(actions.loadItems(criteria));
    // }, [dispatch]);
    //
    // const {loading, item} = useSelector(state => ({
    //     loading: state.pagesReducer.network.loadingItem,
    //     item: state.pagesReducer.network.item,
    // }));
    //

    return (
        <div className="col-xl-9 col-lg-8 col-12 no-equal-item">
            <div className="tab-content">
                {sites.map((value, i) => (
                    <div role="tabpanel" className={`tab-pane fade ${value.id === id ? 'active show' : ''}`}
                         id={`department${i}`}>
                        <div className="single-departments-box-layout1">
                            <div className="single-departments-img">
                                <img alt="single" src="/assets/img/about/about7.jpg"/>
                            </div>

                            <div className="item-content">
                                <div className="item-content-wrap">
                                    <h3 className="item-title title-bar-primary5">
                                        <FormattedMessage id={`app.label.about`}/>
                                    </h3>
                                    <span className="sub-title">
                                            {value.description}
                                </span>
                                    <p>
                                        La ville de St Marc, en plus du dispensaire (SSPE) est dotée de l’hôpital St
                                        Nicolas
                                        communément appelé HSN. Cet Hôpital communautaire de référence dessert non
                                        seulement
                                        les habitants de la commune de St Marc mais toute la population du bas
                                        Artibonite.
                                        En terme de fréquentation mensuelle, L’HSN reçoit en dehors des services
                                        spécialisés
                                        en moyenne près de 3000 visites en cliniques externes, 800 en CPN, 150 en
                                        PF, et
                                        plus de 300 accouchements (physiologiques et césarienne).
                                    </p>


                                    <h3 className="item-title title-bar-primary5">
                                        <FormattedMessage id={`app.label.services`}/>
                                    </h3>
                                    <p>
                                        ......................
                                    </p>


                                    <ul className="department-info">
                                        <li>service1</li>
                                        <li>service2</li>
                                        <li>service3</li>
                                        <li>service4</li>
                                    </ul>


                                    <h3 className="item-title title-bar-primary5">
                                        <FormattedMessage id={`app.label.contact`}/>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                )) }
            </div>
        </div>
    );
});


export default React.memo(withRouter(PageContent))

