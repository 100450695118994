import * as React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import {ToastContainer} from "react-toastify";
import configureStore, {history} from "./redux/store/index";
import initialState from "./constants/utils/initialState";
import Layout from "./components";
import ScriptTag from 'react-script-tag';

const store = configureStore(initialState);

const app = (
<React.Fragment>
    <Provider store={store}>
        <ToastContainer autoClose={2000} position={"top-center"}/>
        <Layout history={history}/>


    </Provider>
</React.Fragment>
);

ReactDOM.render(app, document.getElementById("wrapper"));


