import React from "react";
import {FormattedMessage} from "react-intl";
import {Field, reduxForm} from "redux-form";
import {connect, useDispatch, useSelector} from 'react-redux'
import {validate} from "./validator";
import {renderField} from "../../../../../common/utils/FormComponent";
import actions from "../../../../../../thunks/common/coreThunks";

const onClearForm = (reset, resetStateContact) => {
    reset('contact');
    resetStateContact();

}

const FormC = props => {
    const {handleSubmit, valid, reset, pristine, submitting, msItem, resetStateContact, intl, history, form} = props;


    const {message} = useSelector(state => ({
        message: state.pagesReducer.contact?.message,
    }));
    const dispatch = useDispatch();
    const resetState = () => {
        reset('contactForm');
        dispatch(actions.resetItem({options: ['message']}))

    }

    if (message === 'success') resetState()
    return (

        <form onSubmit={handleSubmit} class="contact-form-box m-4">
            <div class="col col-xs-12">
                <Field
                    className="form-control"
                    id="option-message"
                    name="fullName"
                    component={renderField}
                    type="text"
                    label="app.label.fullname"
                    placeholder="app.placeholder.fullname"
                />

            </div>
            <div class="col col-xs-12">
                <Field
                    className="form-control"
                    id="option-message"
                    name="email"
                    component={renderField}
                    type="email"
                    label="app.label.email"
                    placeholder="app.placeholder.email"
                />

            </div>

            <div class="col col-xs-12">
                <Field
                    className="form-control"
                    id="option-message"
                    name="phone"
                    component={renderField}
                    type="text"
                    label="app.label.phone"
                    placeholder="app.placeholder.phone"
                />

            </div>


            <div class="col col-xs-12">
                <Field
                    className="form-control"
                    id="option-message"
                    name="message"
                    component={renderField}
                    type="textarea"
                    label="app.label.message"
                    placeholder="app.placeholder.message"
                />

            </div>


            <div class="col col-xs-12 form-group margin-b-none">
                <button type="submit"
                        class="item-btn"
                        disabled={pristine || submitting}>
                    <FormattedMessage id={'app.label.send'} defaultMessage={`Envoyez`}/>
                </button>
                <div id="loader">
                    <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
                </div>
            </div>
        </form>

    );
}


const mapStateToProps = (state, props) => (
    {
        initialValues: ""

    })

export default connect(
    mapStateToProps
)(reduxForm({
    form: 'contactForm',
    validate
})(FormC));

