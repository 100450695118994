/**
 * Created by louidorpc on 11/12/20.
 */
import React from 'react';
import {Modal, Button} from 'react-bootstrap'
import {FormattedMessage} from "react-intl";
import {intlConverter} from "../../../constants/utils";
import DownloadLink from "react-download-link";

export const ComponentModal = ({sh, hide, item, language}) => {

    const [show, setShow] = React.useState(sh);

    const getDataFromURL = (url) => new Promise((resolve, reject) => {
        setTimeout(() => {
            fetch(url)
                .then(response => response.blob())
                .then(data => {
                    resolve(data)
                });
        });
    }, 2000);

    const documentType = type => {
        switch (type) {
            case 'pdf':
            case 'PDF':
                return (
                    "/assets/img/icon/pdf.svg"
                )
            case 'csv':
                return (
                    "/assets/img/icon/csv.svg"
                )
            case 'xlsx':
                return (
                    "/assets/img/icon/xls.svg"
                )
            case 'docx':
                return (
                    "/assets/img/icon/docx.svg"
                )
            case 'jpg':
            case 'JPG':
            case 'jpeg':
            case 'JPEG':
                return (
                    "/assets/img/icon/jpg.svg"
                )
            case 'png':
            case 'PNG':
                return (
                    "/assets/img/icon/png.svg"
                )
            default:
                return (
                    "/assets/img/icon/doc.svg"
                )
        }
    }

    return (
        <Modal

            show={sh}
            onHide={hide}
            style={{zIndex: '999999'}}
            dialogClassName="modal-100w"
            size="lg"
            aria-labelledby="example-modal-sizes-title-xl"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg modal-lg">
                    <h3 className="item-title title-bar-primary5 pb-2">
                        {intlConverter(item.name, language)}
                    </h3>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body class="modal-xl">
                <div className="single-departments-box-layout1">
                    <div className="item-content" id={`department${item.id}`}>
                        <div className="item-content-wrap pt-4 mr-4">
                            {item?.description ? <>
                                <p dangerouslySetInnerHTML={{
                                    __html: intlConverter(item.description, language)
                                }}></p>
                            </> : null}

                            <div className="row">
                                {item?.documents?.length > 0 ?
                                    <div className="col-md-12">
                                        <h4 className="item-title title-bar-primary5 pb-2">
                                            Documents
                                        </h4>
                                    </div> : null}
                                {item?.documents?.map(v => (
                                    <div className="col-md-4 col-xl-4 col-4 card ml-4">
                                        <DownloadLink
                                            style={{textDecoration: 'none'}}
                                            label={<div className={'row pt-3'}>
                                                <div className={'col-md-3'}>
                                                    <img src={documentType(v?.fileName?.split('.')[2])}
                                                         width="70px"
                                                         height="70px"/>
                                                </div>
                                                <div className={'col-md-7 overflow-ellip'}>
                                                    <p style={{textDecoration: 'none'}}>{v.documentName}</p></div>
                                                <div className={'col-md-1 mr-1'}>
                                                    <i className="fa fa-download" style={{color: '#f06810'}}></i>
                                                </div>
                                            </div>}
                                            filename={v.documentName + "." + v.fileName?.split('.')[2]}
                                            exportFile={() => Promise.resolve(getDataFromURL(v.fileName))}
                                        />
                                    </div>))}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer class="modal-lg pb-4">
                <div className="pt-4 pb-5 pr-5" style={{fontSIze: '16px'}}>
                    <Button className="float-right font-weight-bolder" onClick={hide}
                            style={{padding: '5px 5px', fontSize: '14px'}}><FormattedMessage
                        id={'app.label.close'}/></Button>
                </div>
            </Modal.Footer>
        </Modal>
    );

}
