import * as React from "react";
import {injectIntl} from "react-intl";
import {getIntlMsg, intlConverter} from "../../../../constants/utils";
import {useContext} from "react";
import {GlobalContext} from "../../../index";

const VisionMissionPage = injectIntl(({intl}) => {

    const {settings, language} = useContext(GlobalContext)

    return (
        <div className="single-departments-box-layout1 pt-0">
            <div className="item-content pt-3">
                <div className="item-content-wrap">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h3 className="item-title title-bar-primary5 m-4">
                                Vision & Mission
                            </h3>
                        </div>


                        <div class="col-lg-12 col-md-12  bg-white">
                            <div class="sub-title m-4">Mission</div>
                            <p dangerouslySetInnerHTML={{
                                __html: intlConverter(settings?.mission, language)
                            }} className="m-4" style={{textAlign: 'justify'}}></p>


                            <div class="sub-title m-4">Vision</div>
                            <p dangerouslySetInnerHTML={{
                                __html: intlConverter(settings?.vision, language)
                            }} className="m-4" style={{textAlign: 'justify'}}></p>

                        </div>

                    </div>

                    <div>

                    </div>
                </div>
            </div>
        </div>
    );
});

export default React.memo(VisionMissionPage)

