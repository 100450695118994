import React, {useContext, useState} from "react";
import {injectIntl, FormattedMessage} from "react-intl";
import {getIntlMsg, intlConverter} from "../../../../constants/utils";
import {Teams} from "../../../../constants/options/data";
import {useParams} from "react-router-dom";
import {LeadershipModal} from './leadershipModal'
import {GlobalContext} from "../../../index";


const LeadershipPage = injectIntl(({intl, item}) => {

        const [state, setState] = useState({
            show: false
        });

        function setShowM() {
            setState({
                show: true
            })
        }

        function hide() {
            setState({
                show: false
            })
        }

        const {language} = useContext(GlobalContext);

        const {show} = state;

        return (
            <>
                <div className="single-departments-box-layout1 pt-5">

                    <div className="item-content pt-3">
                        <div className="item-content-wrap">
                            <div className="headerss" id="myHeader">
                                <h3 className="item-title title-bar-primary5">
                                    Leadership
                                </h3>
                            </div>


                            <span className="sub-title">

                                </span>
                            <div class="row content">
                                {item?.members.length > 0 ? item?.members?.map(value => value.category.name.frContent === "Leadership" &&
                                    <div class="col-lg-12 col-md-12">

                                        <div class="team-box-layout1">
                                            <div class="media media-none-lg media-none-md media-none--xs">
                                                <div class="item-img" style={{width: '200px', height: '200px'}}>
                                                    <img src={value.fileName} alt="Team1"
                                                         class="img-fluid rounded-circle media-img-auto"
                                                         style={{height: 'inherit', width: 'inherit'}}/>
                                                </div>
                                                <div class="media-body">
                                                    <div class="item-content">
                                                        <h4 class="item-title">
                                                            <a href="single-doctor.html">{value.firstname + " " + value.lastname}</a>
                                                        </h4>
                                                        <p class="designation font-weight-bold">{intlConverter(value?.position, language)}</p>

                                                        <div dangerouslySetInnerHTML={{
                                                            __html: intl.formatMessage(
                                                                {id: intlConverter(value.about, language)},
                                                                {value: '<strong>STRONG</strong>'}
                                                            )
                                                        }} style={{textAlign: 'justify'}} className="mr-4"></div>


                                                        <ul class="item-btns">

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>) : null}

                            </div>

                            <div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
);

export default React.memo(LeadershipPage)

