import * as React from "react";
import {NavLink, withRouter, useParams} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import {homeAbout} from '../../../constants/options/data'
import LeadershipPage from './leadership';
import Biography from './biography';
import Mission from './mission';
import Origin from './origin'
import Founder from './founders'


const SideBar = ({item}) => {
    const {module, page, id} = useParams();

    return (
        <>
            <div className={'col-xl-3 col-lg-3 col-12'}>
                <div
                    className="sidebar-widget-area sidebar-break-md   no-equal-item pt-lg-3">
                    <div className="widget widget-department-info">
                        {/*<div className="headerss" id="myHeader">*/}
                        <div>
                            <h3 className="section-title title-bar-primary">
                                <FormattedMessage id="app.label.submenu"
                                                  defaultMessage=""/></h3>
                            <ul className="nav nav-tabs tab-nav-list bg-white">
                                <li className="nav-item">
                                    <a className={'active'} href={`#founder`} data-toggle="tab"
                                       aria-expanded="false">
                                            <span><FormattedMessage id="app.label.our_founder"
                                                                    defaultMessage=""/></span>
                                        <small className="d-block text-uppercase">
                                        </small>
                                    </a>
                                </li>

                                <li className="nav-item">
                                    <a className={''} href={`#leadership`} data-toggle="tab"
                                       aria-expanded="false">
                                        <span>Leadership</span>
                                        <small className="d-block text-uppercase">
                                            {/*{site.description}*/}
                                        </small>
                                    </a>
                                </li>


                                <li className="nav-item">
                                    <a className={''} href={`#origin`} data-toggle="tab"
                                       aria-expanded="false">
                                        <span><FormattedMessage id="app.label.origin" defaultMessage=""/></span>
                                        <small className="d-block text-uppercase">
                                            {/*{site.description}*/}
                                        </small>
                                    </a>
                                </li>

                                <li className="nav-item">
                                    <a className={''} href={`#vision`} data-toggle="tab"
                                       aria-expanded="false">
                                            <span><FormattedMessage id="app.label.mission_vision"
                                                                    defaultMessage=""/></span>
                                        <small className="d-block text-uppercase">
                                        </small>
                                    </a>
                                </li>
                                {/*<li className="nav-item">*/}
                                {/*<a className={''} href={`#biography`} data-toggle="tab"*/}
                                {/*aria-expanded="false">*/}
                                {/*<span><FormattedMessage id="app.label.biography" defaultMessage=""/></span>*/}
                                {/*<small className="d-block text-uppercase">*/}
                                {/*</small>*/}
                                {/*</a>*/}
                                {/*</li>*/}

                            </ul>
                        </div>
                    </div>

                </div>
            </div>
            <div className={'col-xl-9 col-lg-8 col-12 '}>
                <div className="no-equal-item contentt">
                    <div className="tab-content">
                        <div role="tabpanel" className={`tab-pane fade`}
                             id={`leadership`}>
                            <Biography item={item}/>

                        </div>
                        <div role="tabpanel" className={`tab-pane fade`}
                             id={`origin`}>
                            <Origin/>
                        </div>

                        <div role="tabpanel" className={`tab-pane fade`}
                             id={`vision`}>
                            <Mission/>
                        </div>

                        <div role="tabpanel" className={`tab-pane fade pt-5 active show`}
                             id={`founder`}>
                            <Founder item={item}/>
                        </div>

                    </div>
                </div>
            </div>

        </>

    )
}


export default React.memo(withRouter(SideBar))

