import * as validator from '../../../../../../constants/utils/validator'


export const validate = values => {
    const errors = {}

    //NOTE when calling validateString the params are: (label, value, min, max, required=false)
    errors.phone = validator.validateString('phone', values.phone, 3, 50, true)
    errors.fullname = validator.validateString('fullname', values.fullname, 3, 30, true)
    errors.email = validator.validateString('email', values.email, 3, 30, true)
    errors.subject = validator.validateString('subject', values.subject, 3, 30, true)
    errors.message = validator.validateString('message', values.message, 3, 30, true)

    return errors
}


export const normalizeNif = (value, previousValue) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (!previousValue || value.length > previousValue.length) {
        // typing forward
        if (onlyNums.length === 3) {
            return onlyNums + '-'
        }
        if (onlyNums.length === 6) {
            return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3) + '-'
        }
        if (onlyNums.length === 9) {
            return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6) + '-'
        }
    }

    if (onlyNums.length <= 3) {
        return onlyNums
    }
    if (onlyNums.length <= 6) {
        return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3)
    }
    if (onlyNums.length <= 9) {
        return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6)
    }

    return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6, 9) + '-' + onlyNums.slice(9, 10)
}


export const normalizePhone = (value, previousValue) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (!previousValue || value.length > previousValue.length) {
        // typing forward
        if (onlyNums.length === 4) {
            return onlyNums + '-'
        }
        if (onlyNums.length === 8) {
            return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4, 8)
        }

    }

    if (onlyNums.length <= 4) {
        return onlyNums
    }
    if (onlyNums.length <= 8) {
        return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4, 8)
    }

    return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4, 8);
}

export const normalizeCin = (value, previousValue) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')

    if (onlyNums.length <= 10) {
        return onlyNums.slice(0, 10)
    } else {


        if (!previousValue || value.length > previousValue.length) {
            // typing forward
            if (onlyNums.length === 2) {
                return onlyNums + '-'
            }
            if (onlyNums.length === 4) {
                return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2) + '-'
            }
            if (onlyNums.length === 6) {
                return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4) + '-'
            }
            if (onlyNums.length === 10) {
                return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4, 6) + '-' + onlyNums.slice(6, 10) + '-'
            }
            if (onlyNums.length === 12) {
                return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4, 6) + '-' + onlyNums.slice(6, 10) + '-' + onlyNums.slice(10, 12) + '-'
            }
        }

        if (onlyNums.length <= 2) {
            return onlyNums
        }
        if (onlyNums.length <= 4) {
            return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2)
        }
        if (onlyNums.length <= 6) {
            return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4)
        }
        if (onlyNums.length <= 10) {
            return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4, 6) + '-' + onlyNums.slice(6, 10)
        }
        if (onlyNums.length <= 12) {
            return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4, 6) + '-' + onlyNums.slice(6, 10) + '-' + onlyNums.slice(10, 12)
        }

        return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4, 6) + '-' + onlyNums.slice(6, 10) + '-' + onlyNums.slice(10, 12) + '-' + onlyNums.slice(12, 17)
    }
}
