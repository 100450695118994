import * as React from "react";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {useContext} from "react";
import {GlobalContext} from "../../../index";
import {intlConverter} from "../../../../constants/utils";


const Thumbnail = ({fileName, name, description, about, id}) => {

    const {language} = useContext(GlobalContext)
    return (
        <div className="col-xl-4 col-lg-6 col-md-6 col-12">
            <a href={`/sectors/detail/${id}`}>
                <div className="departments-box-layout5">
                    <div className="item-img sector" style={{height: "280px"}}>
                        <img src={fileName} alt="department" className="img-fluid" height="420"/>
                        <div className="item-content">
                            <h3 className="item-title title-bar-primary3">
                                <Link to="#">
                                    {intlConverter(name, language)}
                                </Link>
                            </h3>
                            <div className={'item-title bg-title'}>
                                <p dangerouslySetInnerHTML={{
                                    __html: intlConverter(about, language)
                                }} className="ellipse" style={{fontWeight: 'normal'}}></p>
                            </div>

                            <a href={`/sectors/detail/${id}`} className="item-btn">
                                Detail
                            </a>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    )
}

export default React.memo(Thumbnail)

