import types from "../../actions/types/common/proxyActionTypes";
import initialState from "../../../constants/utils/initialState";

const _proxyReducer = (state = initialState.proxyReducer, action) => {
    switch (action.type) {

        /**
         * LOAD TMA
         * */
        case types.LOAD_TMA:
            return {...state, tma: {...state.tma, loadingItem: action.payload}};
        case types.LOAD_TMA_SUCCEEDED:
            return {...state, tma: {...state.tma, item: action.payload, loadingItems: false}};
        case types.LOAD_TMA_FAILED:
            return {...state, tma: {...state.tma, loadingItem: action.payload}};

        default:
            return state;
    }
};
export default _proxyReducer;
