import React, {useContext, useState} from "react";
import {injectIntl, FormattedMessage} from "react-intl";
import {getIntlMsg, intlConverter} from "../../../../constants/utils";
import {Founders} from "../../../../constants/options/data";
import {useParams} from "react-router-dom";
import {LeadershipModal} from './leadershipModal'
import {GlobalContext} from "../../../index";


const LeadershipPage = injectIntl(({intl, item}) => {
        console.log("===============DHDHDHDH=============", item)

        const [state, setState] = useState({
            show: false
        });

        function setShowM() {
            setState({
                show: true
            })
        }

        function hide() {
            setState({
                show: false
            })
        }

        const {show} = state;
        const {language} = useContext(GlobalContext)

        return (

            <div className="single-departments-box-layout1 pt-0">
                <div className="item-content pt-3">
                    <div className="item-content-wrap">
                        <h3 className="item-title title-bar-primary5">
                            <FormattedMessage id="app.label.our_founder" defaultMessage=""/>
                        </h3>
                        <span className="sub-title">

                                </span>
                        <div class="row">
                            {item?.members.length > 0 ? item?.members?.map(value => value.category.name.frContent === "Founder" &&
                                <div class="col-md-12">

                                    <div class="team-box-layout1">
                                        <div class="media media-none-lg media-none-md media-none--xs">
                                            <div class="item-img" style={{width: '170px'}}>
                                                <img src={value.fileName} alt="Team1"
                                                     class="img-fluid rounded-circle media-img-auto" width='170px'/>
                                            </div>
                                            <div class="media-body">
                                                <div class="item-content">
                                                    <h4 class="item-title">
                                                        <a href="#">{value.lastname + " " + value.firstname}</a>
                                                    </h4>
                                                    <p class="designation pr-3"
                                                       style={{textAlign: 'justify'}}>
                                                        <div dangerouslySetInnerHTML={{
                                                            __html: intlConverter(value?.about, language)
                                                        }} className="slider-paragraph padding-right"
                                                             style={{color: '#fff'}}></div>
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>) : null}
                        </div>

                        <div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export default React.memo(LeadershipPage)

