/**
 * Created by louidorpc on 9/30/20.
 */
import React from 'react'
import {FacebookProvider, CommentsCount, Page, Comments, CustomChat, Like} from 'react-facebook';


export const FacebookPage = () => (

    <FacebookProvider appId="789650755153550" style={{height: '100px', width: '100%'}}>
        <Page href="https://www.facebook.com/zanmilasanteht/" tabs="timeline" height="200px"
              width="500px"/>
    </FacebookProvider>

);

export const FacebookComment = () => (
    <FacebookProvider appId="789650755153550" style={{height: '400px', width: '100%'}}>
        <Comments href="https://www.facebook.com/zanmilasanteht/"  width="500px" height={'200px'}/>
    </FacebookProvider>
);


export const FacebookLikeButton = () => (
    <FacebookProvider appId="789650755153550" style={{height: '100px', width: '100%'}}>
        <Like href="https://www.facebook.com/zanmilasanteht/" olorScheme="dark" showFaces share width="100px"/>
    </FacebookProvider>
);